import styled from "styled-components";

export const Container = styled.div``;

export const GridButtton = styled.div`
    margin-top: 1rem !important;

    @media (max-width: 750px) {
        display: flex;
        justify-content: center;
    }
`;
