import React, {
    useCallback,
    //  useEffect,
    useState,
} from "react";
import ReactDOM from "react-dom";

import notification, {
    Error as notificationError,
} from "../../modules/Notifications";

import Menu from "../../components/_CDOC/Menu";

import useLoading from "../../hooks/useLoading";

import api from "../../service/api";

function CDOC() {
    const [terms, setTerms] = useState(false);

    const { loading } = useLoading();

    const submit = async (event) => {
        event.preventDefault();

        const idLoading = loading();

        try {
            const CNPJ = document.querySelector("#login").value;
            const Senha = document.querySelector("#senha").value;

            if (!CNPJ) {
                loading(idLoading);
                return notification("warning", "Login é obrigatório");
            }

            if (!Senha) {
                loading(idLoading);
                return notification("warning", "Senha é obrigatório");
            }

            if (!terms) {
                loading(idLoading);
                return notification(
                    "warning",
                    "Concorde com os termos para avançar"
                );
            }

            const response = await api.get(
                `/clientes?login=${CNPJ}&Senha=${Senha}`,
                { headers: { Authorization: "" } }
            );

            if (response.data.length === 0) {
                loading(idLoading);
                return notification("error", "Login ou senha inválidos");
            }

            if (!response.data[0].CDOC) {
                loading(idLoading);
                return notification(
                    "error",
                    "Não possui permissão para acessar essa área"
                );
            }

            ReactDOM.render(
                <Menu client={response.data[0]} />,
                document.getElementById("root")
            );

            loading(idLoading);
        } catch (error) {
            notificationError(error);

            loading(idLoading);
        }
    };

    const handleToggleTerms = useCallback(() => {
        setTerms(!terms);
    }, [terms]);

    // useEffect(() => {
    //     document.querySelector("#login").value = "suporte";
    //     document.querySelector("#senha").value = "suporte";
    //     handleToggleTerms();

    //     const event = new MouseEvent("click", {
    //         view: window,
    //         bubbles: true,
    //         cancelable: true,
    //     });
    //     document.querySelector("button[type=submit] ").dispatchEvent(event);
    // }, [handleToggleTerms]);

    return { terms, handleToggleTerms, submit };
}

export default CDOC;
