import React from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import { File as FileMultiple } from "react-og-forms";

import Container from "../../../components/Admin/Container";
import Form from "../../../components/Form";
import Input, { File } from "../../../components/Input";
import ButtonsGrid from "../../../components/ButtonsGrid";
import Button from "../../../components/Button";

import useGraphicMaterial from "./useGraphicMaterial";

import api from "../../../service/api";

function GraphicMaterial() {
    const { id } = useParams();

    const {
        nome,
        imagem,
        arquivo,
        handleOnChangeNome,
        handleOnChangeImagem,
        handleOnDeleteImagem,
        handleOnChangeArquivo,
        destroy,
        handleOnSubmit,
    } = useGraphicMaterial();

    return (
        <Container pageTitle="Material Gráfico">
            <Row className="d-flex justify-content-center">
                <Col md="6" sm="10">
                    <Form onSubmit={handleOnSubmit}>
                        <Input
                            label="Nome*"
                            value={nome}
                            onChange={handleOnChangeNome}
                        />
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <File
                                    label="Imagem*"
                                    width="200"
                                    height="200px"
                                    file={imagem}
                                    accept="image/*"
                                    onUpload={handleOnChangeImagem}
                                    onDeleteFile={handleOnDeleteImagem}
                                />
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <FileMultiple
                                    multiple
                                    value={arquivo}
                                    onChange={handleOnChangeArquivo}
                                    baseUrl={api.defaults.baseURL}
                                    headers={api.defaults.headers}
                                />
                            </Col>
                            <ButtonsGrid>
                                <Button variant="success" type="submit">
                                    <FaPaperPlane />
                                    Enviar
                                </Button>
                                {id && (
                                    <Button
                                        variant="outline-danger"
                                        onClick={destroy}
                                    >
                                        <FaTrash />
                                        Remover
                                    </Button>
                                )}
                            </ButtonsGrid>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default GraphicMaterial;
