const DataTables = (table, data, columns, drawCallback) => {
    const $ = require("jquery");
    const dt = require("datatables.net");
    $.fn.DataTable = dt;

    return $(table).DataTable({
        data,
        columns,
        language: {
            decimal: "",
            emptyTable: "Tabela Vazia",
            info: "Mostrando _START_ até _END_ de _TOTAL_ registros",
            infoEmpty: "Mostrando 0 de 0 registros",
            infoFiltered: "(filtered from _MAX_ total entries)",
            infoPostFix: "",
            thousands: ",",
            lengthMenu: "_MENU_ registros por página",
            loadingRecords: "Carregando...",
            processing: "Processando...",
            search: "Pesquisar:",
            zeroRecords: "Nenhum registro encontrado",
            paginate: {
                first: "Primeira",
                last: "Última",
                next: ">",
                previous: "<",
            },
            aria: {
                sortAscending: ": activate to sort column ascending",
                sortDescending: ": activate to sort column descending",
            },
        },

        drawCallback: () => {
            if (drawCallback) drawCallback();
        },

        ordering: false,

        destroy: true,
    });
};

export default DataTables;
