import React, { useState } from "react";

import HomeCarousel from "../HomeCarousel";
import HomePopup from "../HomePopup";
import CDOCContainer from "../Container";

import useHome from "./useHome";

import { Container } from "./styles";

function Home({ client }) {
    const { ativo } = useHome();

    const [show, setShow] = useState(true);

    return (
        <>
            {ativo && (
                <HomePopup
                    {...{
                        show,
                        onClick: () => {
                            setShow(!show);
                        },
                    }}
                />
            )}
            <CDOCContainer {...{ client }}>
                <Container>
                    <HomeCarousel />
                </Container>
            </CDOCContainer>
        </>
    );
}

export default Home;
