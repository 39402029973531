import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { useHistory, Link } from "react-router-dom";
import { FiFolder, FiPlusCircle, FiLink } from "react-icons/fi";

import Container from "../../../components/Admin/Container";
import Table, { ResponsiveTable } from "../../../components/Table";
import DocInput from "../../../components/ProductsDocInput";

import DataTables from "../../../modules/DataTables";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";

const ShowProducts = () => {
    const history = useHistory();

    const { loading } = useLoading();

    const setReactComponentinTable = useCallback(() => {
        const handleEdit = (id) => {
            history.push(`/ctop/produto/${id}`);
        };

        const setReactComponentinTable = () => {
            function setOpenButtons() {
                const editarEls = document.querySelectorAll(".editar");

                for (const editarEl of editarEls) {
                    ReactDOM.render(
                        <FiFolder
                            className="cursor-pointer"
                            onClick={() => {
                                handleEdit(editarEl.dataset.id);
                            }}
                        />,
                        document.getElementById(editarEl.id)
                    );
                }
            }

            function setDoc1() {
                const docEls = document.querySelectorAll(".cdoc-1");

                docEls.forEach((docEl) => {
                    const { id, file } = docEl.dataset;

                    ReactDOM.render(
                        <DocInput
                            {...{
                                id,
                                uploadedFile: file,
                                type: "CDOC1",
                                reload: index,
                            }}
                        />,
                        document.getElementById(docEl.id)
                    );
                });
            }

            function setDoc2() {
                const docEls = document.querySelectorAll(".cdoc-2");

                docEls.forEach((docEl) => {
                    const { id, file } = docEl.dataset;

                    ReactDOM.render(
                        <DocInput
                            {...{
                                id,
                                uploadedFile: file,
                                type: "CDOC2",
                                reload: index,
                            }}
                        />,
                        document.getElementById(docEl.id)
                    );
                });
            }

            function setDoc3() {
                const docEls = document.querySelectorAll(".cdoc-3");

                docEls.forEach((docEl) => {
                    const { id, file } = docEl.dataset;

                    ReactDOM.render(
                        <DocInput
                            {...{
                                id,
                                uploadedFile: file,
                                type: "CDOC3",
                                reload: index,
                            }}
                        />,
                        document.getElementById(docEl.id)
                    );
                });
            }

            function setDoc4() {
                const docEls = document.querySelectorAll(".cdoc-4");

                docEls.forEach((docEl) => {
                    const { id } = docEl.dataset;

                    ReactDOM.render(
                        <FiLink
                            className="link"
                            onClick={() => {
                                history.push(`/ctop/produto/${id}?goto=cdoc4`);
                            }}
                        />,
                        document.getElementById(docEl.id)
                    );
                });
            }

            setOpenButtons();
            setDoc1();
            setDoc2();
            setDoc3();
            setDoc4();
        };

        setReactComponentinTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);

    const orderProducts = (products = []) => {
        return products.sort((a, b) => {
            if (a.linha.ordem > b.linha.ordem) {
                return 1;
            }

            if (a.linha.ordem < b.linha.ordem) {
                return -1;
            }

            return 0;
        });
    };

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();
            try {
                const response = await api.get("/produtos?_limit=-1");

                const orderedProducts = orderProducts(response.data);

                let serializedData = orderedProducts.map((data) => {
                    const {
                        id: ID,
                        Titulo,
                        linha: { Linha },
                        CDOC1,
                        CDOC2,
                        CDOC3,
                    } = data;

                    return {
                        ID,
                        Titulo,
                        Linha,
                        CDOC1: `<div class="cdoc-1" data-id="${ID}" data-file="${CDOC1}" id="cdoc1-${ID}"></div>`,
                        CDOC2: `<div class="cdoc-2" data-id="${ID}" data-file="${CDOC2}" id="cdoc2-${ID}"></div>`,
                        CDOC3: `<div class="cdoc-3" data-id="${ID}" data-file="${CDOC3}" id="cdoc3-${ID}"></div>`,
                        CDOC4: `<div class="cdoc-4" data-id="${ID}" id="cdoc4-${ID}"></div>`,
                        Abrir: `<div class="editar" data-id="${ID}" id="editar-${ID}"></div>`,
                    };
                });

                DataTables(
                    "#products-table",
                    serializedData,
                    [
                        { title: "Titulo", data: "Titulo" },
                        { title: "Linha", data: "Linha" },
                        { title: "Literatura", data: "CDOC1" },
                        { title: "FISPQ", data: "CDOC2" },
                        { title: "Laudos", data: "CDOC3" },
                        {
                            title: "Material publicitário de apoio",
                            data: "CDOC4",
                        },
                        { title: "Abrir", data: "Abrir" },
                    ],
                    () => {
                        setReactComponentinTable();
                    }
                );
            } catch (error) {
                console.log(error);
            } finally {
                loading(idLoading);
            }
        }

        index();
    }, [loading, setReactComponentinTable]);

    useEffect(() => {
        index();
    }, [index]);

    return (
        <Container pageTitle="Listar Produtos">
            <div className="grid-add">
                <Link to="/ctop/produto" className="add">
                    <FiPlusCircle />
                </Link>
            </div>
            <ResponsiveTable>
                <Table id="products-table"></Table>
            </ResponsiveTable>
        </Container>
    );
};

export default ShowProducts;
