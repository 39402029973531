import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import { useParams } from "react-router";

import Container from "../../../components/Admin/Container";
import Form from "../../../components/Form";
import Input, { File } from "../../../components/Input";
import ButtonsGrid from "../../../components/ButtonsGrid";
import Button from "../../../components/Button";

import useEvent from "./useEvent";

function Event() {
    const { id } = useParams();

    const {
        data,
        arquivo,
        handleOnChange,
        handleOnChangeArquivo,
        handleOnDeleteArquivo,
        handleOnSubmit,
        destroy,
    } = useEvent();

    return (
        <Container pageTitle="Evento">
            <Row className="d-flex justify-content-center">
                <Col md="6" sm="10">
                    <Form onSubmit={handleOnSubmit}>
                        <Input
                            label="Título*"
                            name="titulo"
                            value={data.titulo}
                            onChange={handleOnChange}
                        />

                        <Row>
                            <Col>
                                <Input
                                    label="Início*"
                                    name="inicio"
                                    type="date"
                                    value={data.inicio}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col>
                                <Input
                                    label="Fim*"
                                    name="fim"
                                    type="date"
                                    value={data.fim}
                                    onChange={handleOnChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <File
                                    label="Arquivo*"
                                    width="200"
                                    height="200px"
                                    accept=".pdf"
                                    fileType="pdf"
                                    file={arquivo}
                                    onUpload={handleOnChangeArquivo}
                                    onDeleteFile={handleOnDeleteArquivo}
                                />
                            </Col>
                        </Row>
                        <ButtonsGrid>
                            <Button variant="success" type="submit">
                                <FaPaperPlane />
                                Enviar
                            </Button>
                            {id && (
                                <Button
                                    variant="outline-danger"
                                    onClick={destroy}
                                >
                                    <FaTrash />
                                    Remover
                                </Button>
                            )}
                        </ButtonsGrid>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Event;
