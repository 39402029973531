import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(15px) brightness(200%);

    img {
        width: 100%;
        max-width: 25rem;
    }

    .react-og-spinner {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
    }
`;
