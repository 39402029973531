import styled from "styled-components";

const Index = styled.label`
    font-family: var(--font-title);
    font-weight: 600;
    color: var(--gray-2);
    display: flex;
    flex-direction: column;
    padding: 0 24px !important;
    margin-bottom: 12px !important;
`;

export default Index;
