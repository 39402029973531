import React, { useState, useCallback, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import Button from "../Button";
import { Switch } from "../Input";

import Api from "../../service/api";

import useLoading from "../../hooks/useLoading";

import Notification, { Error } from "../../modules/Notifications";

import { Container, GridButtton } from "./styles";

function Index() {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [id, setId] = useState(0);
    const [ativo, setAtivo] = useState(false);

    const { loading } = useLoading();

    async function handleOnSubmit() {
        const idLoading = loading();
        try {
            const html = document.querySelector("#editor-html-popup").value;

            if (html === "<p></p>") {
                Notification("warning", "Texto é obrigatório");
            }

            if (id === 0) {
                await Api.post("/configuracoes-popup-cookies", { html, ativo });

                Notification("success", "Configurado popup de cookies");

                loading(idLoading);
                return;
            }

            await Api.put(`/configuracoes-popup-cookies/${id}`, {
                html,
                ativo,
            });

            Notification("success", "Configuração popup de cookies atualizada");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get("/configuracoes-popup-cookies");

                if (response.data.length === 0) {
                    loading(idLoading);
                    return;
                }

                setId(response.data[0].id);

                const html = response.data[0].html;
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(
                        contentBlock.contentBlocks
                    );
                    const editorState =
                        EditorState.createWithContent(contentState);
                    setEditorState(editorState);
                }

                setAtivo(response.data[0].ativo);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    useEffect(() => {
        index();
    }, [index]);

    return (
        <Container>
            <div className="d-flex justify-content-end">
                <Switch
                    type="checkbox"
                    label="Ativo"
                    id="is-actived"
                    checked={ativo}
                    onChange={(event) => {
                        setAtivo(event.target.checked);
                    }}
                />
            </div>

            <div>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(value) => {
                        setEditorState(value);
                    }}
                />
                <textarea
                    id="editor-html-popup"
                    style={{ visibility: "hidden", position: "absolute" }}
                    disabled
                    value={draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                    )}
                />
            </div>
            <GridButtton>
                <Button variant="success" onClick={handleOnSubmit}>
                    Enviar
                </Button>
            </GridButtton>
        </Container>
    );
}

export default Index;
