import styled from "styled-components";

export const Container = styled.div`
    .multiple-docs-grid {
        display: flex;
        justify-content: flex-end;

        .file-grid-add {
            margin-top: 1rem !important;

            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                margin: 0.5rem auto 0 !important;
            }
        }

        .multiple-docs-show-grid {
            width: 100%;

            display: flex;
            align-items: center;

            .col-md-4 {
                margin-top: 0.5rem !important;
            }
        }
    }
`;
