import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_HTML_TO_PDF,
    headers: {
        Authorization: "",
    },
});

export default api;
