import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import Logo from "../../assets/Images/logo-noordhen.svg";
import LogoInputs from "../../assets/Images/logo-inputs.png";
import LogoSvs from "../../assets/Images/logo-svs.png";
import Facebook from "../../assets/Images/FACE.jpg";
import Instagram from "../../assets/Images/INSTA.jpg";
import Whatsapp from "../../assets/Images/WHATS.jpg";
import Youtube from "../../assets/Images/YOUTUBE.jpg";

import Img from "../../components/Img";

import "./styles.css";

const Footer = () => (
    <Row className="footer m-0">
        <Col sm="12" md="3" className="footer-nav">
            <Link to="/">Página Inicial</Link>
            <Link to="/empresa">Empresa</Link>
            <Link to="/produtos-e-mercado">Produtos e Mercados</Link>
            <Link to="/central-de-documentos">
                <Img
                    src={LogoInputs}
                    className="border-0 shadow-none"
                    max-width="100px"
                />
            </Link>
            <Link to="/contato">Contato</Link>
        </Col>
        <Col sm="12" md="6" className="footer-copyright">
            <Row className="d-flex justify-content-between ">
                <p>
                    Noordhen Brasil - Copyright © 2020 | Todos os direitos
                    reservados.
                </p>
                <a
                    href="https://www.svs.com.br/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Img
                        src={LogoSvs}
                        max-width="64px"
                        max-height="30px"
                        className="align-self-end"
                    />
                </a>
            </Row>
        </Col>
        <Col sm="12" md="3" className="footer-nordhen">
            <Col sm="10" className="footer-nordhen-container">
                <Col className="footer-logo">
                    <Img
                        className="border-0 shadow-none"
                        src={Logo}
                        max-width="120px"
                    ></Img>
                </Col>
                <Row className="footer-social_media">
                    <a
                        href="https://www.facebook.com/pages/Noordhen-Brasil/549914461844925"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={Facebook}
                            className="border-0 shadow-none"
                            max-width="32px"
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/noordhenbrasil/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={Instagram}
                            className="border-0 shadow-none"
                            max-width="32px"
                        />
                    </a>
                    <a
                        href="https://www.youtube.com/channel/UCkWBokjxZenW_qnqhJtyAVQ"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={Youtube}
                            className="border-0 shadow-none"
                            max-width="32px"
                        />
                    </a>
                    <a
                        href="https://api.whatsapp.com/send?phone=5512982205641"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={Whatsapp}
                            className="border-0 shadow-none"
                            max-width="32px"
                        />
                    </a>
                </Row>
            </Col>
        </Col>
    </Row>
);

export default Footer;
