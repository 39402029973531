import React, { useCallback, useEffect } from "react";
import { render } from "react-dom";

import Products from "../Products";
import Contact from "../Contact";
import Home from "../Home";
import Releases from "../Releases";
import AccessoriesAndEquipments from "../AccessoriesAndEquipments";
import GraphicMaterial from "../GraphicMaterial";
import Events from "../Events";
import App from "../../../App";

function useMenu(client) {
    function handleOnClickProduct() {
        render(
            <Products {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickEvents() {
        render(
            <Events {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickContact() {
        render(
            <Contact {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickReleases() {
        render(
            <Releases {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickAccessoriesAndEquipments() {
        render(
            <AccessoriesAndEquipments {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleOnClickGraphicMaterial() {
        render(
            <GraphicMaterial {...{ client }} />,
            document.getElementById("cdoc-main")
        );
    }

    function handleSignOut() {
        render(<App />, document.getElementById("root"));
    }

    const handleOnClickHome = useCallback(() => {
        render(<Home {...{ client }} />, document.getElementById("cdoc-main"));
    }, [client]);

    useEffect(() => {
        handleOnClickHome();
    }, [handleOnClickHome]);

    return {
        handleSignOut,
        handleOnClickProduct,
        handleOnClickContact,
        handleOnClickHome,
        handleOnClickReleases,
        handleOnClickAccessoriesAndEquipments,
        handleOnClickGraphicMaterial,
        handleOnClickEvents,
    };
}

export default useMenu;
