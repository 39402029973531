import React, { useEffect } from "react";

import api, { publicRequest } from "../../service/api";

import PublicContainer from "../../components/Container";

import useLoading from "../../hooks/useLoading";

import { Container } from "./styles";

function Index() {
    const { loading } = useLoading();

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/configuracoes-popup-cookies",
                    publicRequest
                );

                if (response.data.length === 0) {
                    loading(idLoading);
                    return;
                }

                const html = response.data[0].html;

                document.querySelector("#content-cookies").innerHTML = html;

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    return (
        <PublicContainer>
            <Container>
                <div id="content-cookies"></div>
            </Container>
        </PublicContainer>
    );
}

export default Index;
