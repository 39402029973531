import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import Select from "react-select";
import produce from "immer";

import "../../../assets/Style/global.css";

import AuthContainer from "../../../components/Admin/Container";
import Fieldset from "../../../components/Fieldset";
import Input, { File, Switch } from "../../../components/Input";
import Button from "../../../components/Button";
import Form from "../../../components/Form";
import Application from "../../../components/Application";
import Line from "../../../components/Line";
import Video from "../../../components/Video";
import ButtonsGrid from "../../../components/ButtonsGrid";
import Label from "../../../components/Label";

import Notification, { Error } from "../../../modules/Notifications";
import Confirm from "../../../modules/AlertConfirm";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";

import { Container } from "./styles";

const Index = (props) => {
    const history = useHistory();

    const { loading } = useLoading();

    const { id } = props.match.params;

    const [data, setData] = useState({});

    const [imageProduct, setImageProduct] = useState({});

    const [CDOC1, setCDOC1] = useState({});

    const [CDOC2, setCDOC2] = useState({});

    const [CDOC3, setCDOC3] = useState({});

    const [CDOC4, setCDOC4] = useState({});

    const [productInformations, setProductInformations] = useState([]);

    const [linhas, setLinhas] = useState([]);

    const [aplicacoes, setAplicacoes] = useState([]);

    const [videos, setVideos] = useState([]);

    const [clients, setClients] = useState([]);

    const [uploadedFile, setUploadedFile] = useState({});

    useEffect(() => {
        async function indexLinhas() {
            const idLoading = loading();

            const response = await api.get("/linhas");

            setLinhas(response.data);

            loading(idLoading);
        }

        async function indexAplicacoes() {
            const idLoading = loading();

            const response = await api.get(
                "/aplicacaos?_sort=Aplicacao:ASC&_limit=-1"
            );

            setAplicacoes(response.data);

            loading(idLoading);
        }

        indexLinhas();

        indexAplicacoes();
    }, [loading]);

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await api.get(`/produtos/${id}`);

                response.data.aplicacaos = response.data.aplicacaos.map(
                    (item) => item.id
                );

                response.data.clientes = response.data.clientes.map((item) => ({
                    value: item.id,
                    label: item.Nome,
                }));

                response.data.linha = response.data.linha.id;

                setVideos(response.data.videos);

                delete response.data.videos;

                setImageProduct({ preview: response.data.image });

                setCDOC1({ preview: response.data.CDOC1 });

                setCDOC2({ preview: response.data.CDOC2 });

                setCDOC3({ preview: response.data.CDOC3 });

                setCDOC4({ preview: response.data.CDOC4 });

                setProductInformations(response.data.produtos_informacoes);

                response.data.exclusivo = response.data.exclusivo
                    ? response.data.exclusivo
                    : false;

                setData(response.data);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        if (id) show();
    }, [id, loading]);

    useEffect(() => {
        show();
    }, [show]);

    useEffect(() => {
        const result = Object.fromEntries(
            new URLSearchParams(String(props.location.search).replace("?", ""))
        );

        if (result.goto === "cdoc4") {
            document.querySelector("#cdoc4-container").scrollIntoView();
        }
    }, [props]);

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                if (data.exclusivo) {
                    const response = await api.get("/clientes?_limit=-1");

                    const serializedData = response.data.map((item) => ({
                        value: item.id,
                        label: item.Nome,
                    }));

                    setClients(serializedData);
                } else {
                    setClients([]);
                }

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [data, loading]);

    function checked(check) {
        return data.aplicacaos && data.aplicacaos.includes(check);
    }

    function handleAddVideo() {
        const videoUrl = document.querySelector("#video-url").value;

        if (!videoUrl) return;

        setVideos([...videos, { link: videoUrl }]);

        document.querySelector("#video-url").value = "";
    }

    const handleValidate = (data) => {
        if (!data.Titulo) {
            return { status: false, message: "Informar título é obrigatório" };
        }

        if (data.aplicacaos.length === 0) {
            return {
                status: false,
                message: "Informar uma aplicação é obrigatório",
            };
        }

        if (!data.linha)
            return {
                status: false,
                message: "Informar uma linha é obrigatório",
            };

        return { status: true };
    };

    async function uploadProductInformations(id) {
        productInformations.forEach(async (item) => {
            const idLoading = loading();

            const response = await uploadFile(item.arquivo.file);

            await api.post("/produtos-informacoes", {
                arquivo: {
                    ...response,
                    preview: `${api.defaults.baseURL}${response.url}`,
                },
                produto: id,
            });

            loading(idLoading);
        });
    }

    const create = async (data) => {
        try {
            const idLoading = loading();

            const response = await api.post("/produtos", data);

            await uploadFiles(response.data.id);

            await uploadVideos(response.data.id);

            await uploadProductInformations(response.data.id);

            Notification("success", "Produto cadastrado");

            history.push("/ctop/produtos");

            loading(idLoading);

            return response.data;
        } catch (error) {
            console.log(error);

            Error(error);
        }
    };

    async function update(data) {
        const idLoading = loading();
        try {
            const response = await api.put(`/produtos/${id}`, data);

            await uploadFiles(response.data.id);

            await uploadVideos(response.data.id);

            Notification("success", "Produto alterado");

            loading(idLoading);

            history.push("/ctop/produtos");
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    const uploadVideos = async (id) => {
        for (const video of videos) {
            if (!video.id) {
                const idLoading = loading();

                await api.post("/videos", {
                    link: video.link,
                    produto: id,
                });

                loading(idLoading);
            }
        }
    };

    async function uploadFile(file) {
        const idLoading = loading();

        let data = new FormData();
        data.append("files", file);

        const response = await api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    }

    const uploadFiles = async (id) => {
        const idLoading = loading();

        try {
            const Image = imageProduct.file;
            if (Image) {
                const response = await uploadFile(Image);

                await api.put(`/produtos/${id}`, {
                    image: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!imageProduct.preview) {
                await api.put(`/produtos/${id}`, {
                    image: "",
                });
            }

            const cDoc1File = CDOC1.file;
            if (cDoc1File) {
                const response = await uploadFile(cDoc1File);

                await api.put(`/produtos/${id}`, {
                    CDOC1: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!CDOC1.preview) {
                await api.put(`/produtos/${id}`, {
                    CDOC1: "",
                });
            }

            const cDoc2File = CDOC2.file;
            if (cDoc2File) {
                const response = await uploadFile(cDoc2File);

                await api.put(`/produtos/${id}`, {
                    CDOC2: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!CDOC2.preview) {
                await api.put(`/produtos/${id}`, {
                    CDOC2: "",
                });
            }

            const cDoc3File = CDOC3.file;
            if (cDoc3File) {
                const response = await uploadFile(cDoc3File);

                await api.put(`/produtos/${id}`, {
                    CDOC3: `${api.defaults.baseURL}${response.url}`,
                });
            } else if (!CDOC3.preview) {
                await api.put(`/produtos/${id}`, {
                    CDOC3: "",
                });
            }

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const processData = (data) => {
        if (Array.isArray(data.clientes)) {
            data.clientes = data.clientes.map((item) => item.value);
        }

        return data;
    };

    const submit = async (event) => {
        try {
            event.preventDefault();
            const processedData = processData(data);

            const validate = handleValidate(processedData);
            if (!validate.status) {
                Notification("warning", validate.message);
                return;
            }

            if (!id) return create(processedData);

            return update(processedData);
        } catch (error) {
            Error(error);
        }
    };

    const destroy = async (event) => {
        event.preventDefault();

        Confirm(
            "Remover Produto",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/produtos/${id}`);

                    Notification("success", "Produto removido");

                    loading(idLoading);

                    history.push("/ctop/produtos");
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    async function createProductInfo() {
        const idLoading = loading();

        try {
            if (!id) {
                setProductInformations([
                    ...productInformations,
                    { arquivo: uploadedFile },
                ]);
            } else {
                if (Object.keys(uploadedFile).length === 0) return;

                await api.post("/produtos-informacoes", {
                    arquivo: uploadedFile,
                    produto: id,
                });
            }

            setUploadedFile({});

            show();

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <AuthContainer
            pageTitle={`${
                !props.match.params.id ? "Cadastrar" : "Editar"
            } Produto`}
        >
            <Container>
                <Form onSubmit={submit}>
                    <div className="row">
                        <Col className="d-flex justify-center align-items-center">
                            <File
                                label="Imagem:"
                                accept=".jpg, .png"
                                file={imageProduct}
                                width="280"
                                height="154px"
                                onUpload={(files) => {
                                    const file = files[0];

                                    setImageProduct({
                                        file,
                                        preview: URL.createObjectURL(file),
                                    });
                                }}
                                onDeleteFile={() => {
                                    setImageProduct({});
                                }}
                            />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                id="Titulo"
                                label="Título:"
                                value={data.Titulo}
                                onChange={(event) => {
                                    setData({
                                        ...data,
                                        Titulo: event.target.value,
                                    });
                                }}
                            />
                        </Col>
                    </div>
                    <Fieldset>
                        <header className="d-flex justify-content-between align-items-center">
                            <h3>Exclusivo</h3>
                            <Switch
                                id="exclusivo"
                                label="Ativo"
                                checked={data.exclusivo}
                                onChange={() => {
                                    setData({
                                        ...data,
                                        exclusivo: !data.exclusivo,
                                    });
                                }}
                            />
                        </header>
                        {clients.length > 0 && (
                            <section className="d-flex flex-wrap">
                                <Label className="col">
                                    <span>
                                        Escolha abaixo ou digite a palavra chave
                                    </span>
                                    <Select
                                        id="search-product"
                                        label="Nome do Produto"
                                        type="text"
                                        options={clients}
                                        defaultValue={data.clientes}
                                        onChange={(event) => {
                                            setData({
                                                ...data,
                                                clientes: event,
                                            });
                                        }}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        isMulti
                                    />
                                </Label>
                            </section>
                        )}
                    </Fieldset>
                    <Fieldset>
                        <h3>Linhas</h3>
                        <div className="d-flex flex-wrap">
                            {linhas.map((linha) => (
                                <Line
                                    key={linha.id}
                                    label={linha.Linha}
                                    checked={
                                        parseInt(data.linha) ===
                                        parseInt(linha.id)
                                    }
                                    onClick={() => {
                                        setData({
                                            ...data,
                                            linha: linha.id,
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Fieldset>
                    <Fieldset>
                        <h3>Aplicações</h3>
                        <div className="d-flex flex-wrap">
                            {aplicacoes.map((aplicacao) => (
                                <Application
                                    key={aplicacao.id}
                                    label={aplicacao.Aplicacao}
                                    checked={checked(aplicacao.id)}
                                    onClick={() => {
                                        if (checked(aplicacao.id)) {
                                            const array =
                                                data.aplicacaos.filter(
                                                    (item) =>
                                                        item !== aplicacao.id
                                                );
                                            setData({
                                                ...data,
                                                aplicacaos: array,
                                            });
                                            return;
                                        }

                                        setData({
                                            ...data,
                                            aplicacaos: [
                                                ...(data.aplicacaos || []),
                                                aplicacao.id,
                                            ],
                                        });
                                    }}
                                />
                            ))}
                        </div>
                    </Fieldset>

                    <Fieldset>
                        <h3>Central de Documentos</h3>
                        <Row className="justify-center">
                            <Row className="col-12 d-flex justify-content-md-between">
                                <File
                                    label="Literatura"
                                    accept=".pdf"
                                    fileType="pdf"
                                    file={CDOC1}
                                    height="250px"
                                    onUpload={(files) => {
                                        const file = files[0];

                                        setCDOC1({
                                            file,
                                            preview: URL.createObjectURL(file),
                                        });
                                    }}
                                    onDeleteFile={() => {
                                        setCDOC1({});
                                    }}
                                />
                                <File
                                    label="FISPQ"
                                    accept=".pdf"
                                    fileType="pdf"
                                    file={CDOC2}
                                    height="250px"
                                    onUpload={(files) => {
                                        const file = files[0];

                                        setCDOC2({
                                            file,
                                            preview: URL.createObjectURL(file),
                                        });
                                    }}
                                    onDeleteFile={() => {
                                        setCDOC2({});
                                    }}
                                />
                                <File
                                    label="Laudos"
                                    accept=".pdf"
                                    fileType="pdf"
                                    file={CDOC3}
                                    height="250px"
                                    onUpload={(files) => {
                                        const file = files[0];

                                        setCDOC3({
                                            file,
                                            preview: URL.createObjectURL(file),
                                        });
                                    }}
                                    onDeleteFile={() => {
                                        setCDOC3({});
                                    }}
                                />
                            </Row>
                            <Row
                                id="cdoc4-container"
                                className="col-12 multiple-docs-grid"
                            >
                                <h3 className="col-12">
                                    Material publicatário de apoio
                                </h3>
                                <div className="multiple-docs-show-grid row">
                                    {productInformations.map((doc, index) => (
                                        <Col
                                            md="4"
                                            style={{
                                                marginTop: "0.5rem!important",
                                            }}
                                        >
                                            <File
                                                key={index}
                                                accept=".pdf"
                                                fileType="pdf"
                                                file={doc.arquivo}
                                                height="250px"
                                                onUpload={async (files) => {
                                                    const file = files[0];

                                                    if (!id) {
                                                        setProductInformations(
                                                            produce(
                                                                productInformations,
                                                                (
                                                                    draftState
                                                                ) => {
                                                                    draftState[
                                                                        index
                                                                    ].arquivo =
                                                                        {
                                                                            file,
                                                                            preview:
                                                                                URL.createObjectURL(
                                                                                    file
                                                                                ),
                                                                        };
                                                                }
                                                            )
                                                        );
                                                    } else {
                                                        Confirm(
                                                            "Alterar Arquivo",
                                                            "Tem certeza que deseja alterar ?",
                                                            async () => {
                                                                const idLoading =
                                                                    loading();

                                                                try {
                                                                    const response =
                                                                        await uploadFile(
                                                                            file
                                                                        );

                                                                    await api.delete(
                                                                        `/upload/files/${doc.arquivo.id}`
                                                                    );

                                                                    await api.put(
                                                                        `/produtos-informacoes/${doc.id}`,
                                                                        {
                                                                            arquivo:
                                                                                {
                                                                                    ...response,
                                                                                    preview: `${api.defaults.baseURL}${response.url}`,
                                                                                },
                                                                        }
                                                                    );

                                                                    show();

                                                                    loading(
                                                                        idLoading
                                                                    );
                                                                } catch (error) {
                                                                    Error(
                                                                        error
                                                                    );

                                                                    loading(
                                                                        idLoading
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }
                                                }}
                                                onDeleteFile={() => {
                                                    if (!id) {
                                                        setProductInformations(
                                                            produce(
                                                                productInformations,
                                                                (
                                                                    draftState
                                                                ) => {
                                                                    draftState.splice(
                                                                        index,
                                                                        1
                                                                    );
                                                                }
                                                            )
                                                        );
                                                    } else {
                                                        Confirm(
                                                            "Remover Arquivo",
                                                            "Tem certeza que deseja remover ?",
                                                            async () => {
                                                                const idLoading =
                                                                    loading();

                                                                await api.delete(
                                                                    `/produtos-informacoes/${doc.id}`
                                                                );

                                                                await api.delete(
                                                                    `/upload/files/${doc.arquivo.id}`
                                                                );

                                                                loading(
                                                                    idLoading
                                                                );

                                                                show();
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </Col>
                                    ))}

                                    {!!Object.keys(CDOC4).length &&
                                        CDOC4.preview && (
                                            <Col
                                                md="4"
                                                style={{
                                                    marginTop:
                                                        "0.5rem!important",
                                                }}
                                            >
                                                <File
                                                    accept=".pdf"
                                                    fileType="pdf"
                                                    file={CDOC4}
                                                    height="250px"
                                                    onUpload={async (files) => {
                                                        const file = files[0];

                                                        Confirm(
                                                            "Alterar Arquivo",
                                                            "Tem certeza que deseja alterar ?",
                                                            async () => {
                                                                const idLoading =
                                                                    loading();

                                                                try {
                                                                    const response =
                                                                        await uploadFile(
                                                                            file
                                                                        );

                                                                    await api.put(
                                                                        `/produtos/${id}`,
                                                                        {
                                                                            CDOC4: "",
                                                                        }
                                                                    );

                                                                    await api.post(
                                                                        "/produtos-informacoes",
                                                                        {
                                                                            arquivo:
                                                                                {
                                                                                    ...response,
                                                                                    preview: `${api.defaults.baseURL}${response.url}`,
                                                                                },
                                                                            produto:
                                                                                id,
                                                                        }
                                                                    );

                                                                    show();

                                                                    loading(
                                                                        idLoading
                                                                    );
                                                                } catch (error) {
                                                                    Error(
                                                                        error
                                                                    );

                                                                    loading(
                                                                        idLoading
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }}
                                                    onDeleteFile={() => {
                                                        Confirm(
                                                            "Remover Arquivo",
                                                            "Tem certeza que deseja remover ?",
                                                            async () => {
                                                                const idLoading =
                                                                    loading();

                                                                await api.put(
                                                                    `/produtos/${id}`,
                                                                    {
                                                                        CDOC4: "",
                                                                    }
                                                                );

                                                                loading(
                                                                    idLoading
                                                                );

                                                                show();
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        )}
                                </div>
                                <div className="file-grid-add">
                                    <File
                                        label="Adicionar"
                                        labelStyle={{ fontSize: "1.4rem" }}
                                        accept=".pdf"
                                        fileType="pdf"
                                        file={uploadedFile}
                                        height="250px"
                                        onUpload={async (files) => {
                                            const file = files[0];

                                            if (!id) {
                                                setUploadedFile({
                                                    file,
                                                    preview:
                                                        URL.createObjectURL(
                                                            file
                                                        ),
                                                });
                                            } else {
                                                const response =
                                                    await uploadFile(file);

                                                setUploadedFile({
                                                    ...response,
                                                    preview: `${api.defaults.baseURL}${response.url}`,
                                                });
                                            }
                                        }}
                                        onDeleteFile={async () => {
                                            const idLoading = loading();

                                            if (
                                                Object.keys(uploadedFile)
                                                    .length === 0
                                            ) {
                                                loading(idLoading);
                                                return;
                                            }

                                            if (uploadedFile.id) {
                                                await api.delete(
                                                    `/upload/files/${uploadedFile.id}`
                                                );
                                            }

                                            setUploadedFile({});

                                            loading(idLoading);
                                        }}
                                    />

                                    <Button
                                        variant="success"
                                        onClick={createProductInfo}
                                    >
                                        <FaPaperPlane />
                                        Enviar
                                    </Button>
                                </div>
                            </Row>
                        </Row>
                    </Fieldset>
                    <Fieldset>
                        <h3>Vídeos</h3>
                        <div className="d-flex flex-wrap justify-center">
                            {videos.map((video) => (
                                <Video
                                    key={Math.random()}
                                    src={video.link}
                                    onDelete={async (event) => {
                                        event.preventDefault();
                                        const idLoading = loading();

                                        const filterVideos = videos.filter(
                                            (v) => {
                                                if (v.link !== video.link)
                                                    return true;

                                                return false;
                                            }
                                        );

                                        if (video.id) {
                                            await api.delete(
                                                `/videos/${video.id}`
                                            );
                                        }

                                        setVideos(filterVideos);

                                        loading(idLoading);
                                    }}
                                />
                            ))}
                        </div>
                        <Row>
                            <Input type="text" id="video-url" />
                            <Button
                                className="button"
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleAddVideo();
                                }}
                            >
                                Adicionar
                            </Button>
                        </Row>
                    </Fieldset>
                    <ButtonsGrid>
                        <Button variant="success" type="submit">
                            <FaPaperPlane />
                            Enviar
                        </Button>
                        {id && (
                            <Button variant="outline-danger" onClick={destroy}>
                                <FaTrash />
                                Remover
                            </Button>
                        )}
                    </ButtonsGrid>
                </Form>
            </Container>
        </AuthContainer>
    );
};

export default Index;
