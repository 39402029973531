import React from "react";
import Styled from "styled-components";

import Container from "../../components/Container";
import Img from "../../components/Img";

import LogoInputs from "../../assets/Images/logo-inputs.png";
import { useEffect } from "react";

import useLoading from "../../hooks/useLoading";

import Api from "../../service/api";

import { Error } from "../../modules/Notifications";

const Header = Styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        margin: 0!important;
    }
`;

const Section = Styled.section`

`;

const Index = () => {
    const { loading } = useLoading();

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-termos-de-usos",
                    {
                        headers: {
                            Authorization: "",
                        },
                    }
                );

                if (response.data.length === 0) {
                    loading(idLoading);
                    return;
                }

                const html = response.data[0].html;

                document.querySelector("#contant-term").innerHTML = html;

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    return (
        <Container>
            <Header>
                <h1>Termos e condições de uso</h1>
                <Img
                    src={LogoInputs}
                    className="shadow-none border-0"
                    max-width="120px"
                    max-height="54px"
                />
            </Header>
            <Section id="contant-term"></Section>
        </Container>
    );
};

export default Index;
