import React from "react";
import { Row } from "react-bootstrap";

import Container from "../../components/Container";
import Input from "../../components/Input";
import Form from "../../components/Form";
import ButtonsGrid from "../../components/ButtonsGrid";
import Button from "../../components/Button";
import Img from "../../components/Img";

import LogoInput from "../../assets/Images/logo-inputs.png";

import useCDOC from "./useCDOC";

import { Header, FormGrid } from "./styles";

const Index = () => {
    const { terms, handleToggleTerms, submit } = useCDOC();

    return (
        <Container>
            <Header>
                <Img
                    src={LogoInput}
                    alt="Logo input noordhen"
                    className="border-0 shadow-none"
                    max-width="20%"
                    sm-max-width="40%"
                />
            </Header>
            <hr />
            <div id="cdoc-main">
                <h2>Central de Documentos</h2>
                <FormGrid md="6" sm="12">
                    <Form className="col" onSubmit={submit}>
                        <Input
                            label="Login*"
                            className="col"
                            type="text"
                            id="login"
                        />
                        <Input
                            label="Senha*"
                            className="col"
                            type="password"
                            id="senha"
                        />
                        <Row className="d-flex justify-center">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={terms}
                                    onChange={handleToggleTerms}
                                />{" "}
                                Eu li e concordo com os{" "}
                                <a href="/termos-de-uso-cdoc" target="_black">
                                    Termos e condições de uso
                                </a>
                            </label>
                        </Row>
                        <ButtonsGrid>
                            <Button variant="success" type="submit">
                                Entrar
                            </Button>
                        </ButtonsGrid>
                    </Form>
                </FormGrid>
            </div>
        </Container>
    );
};

export default Index;
