import React from "react";
import Styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

import Img from "../Img";
import ButtonsGrid from "../ButtonsGrid";

const Container = Styled.div`
    width: 260px;
    min-height: 470px;

    background-color: var(--green);
    color: var(--white);

    margin: 1rem!important;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-shadow: var(--shadow);

    & p {
        font-size: 1.5rem;
    }
    
    & button {
        font-size: 1.5rem!important;
        margin-bottom: 1rem!important;

        font-family: var(--font-title);
        font-weight: 600;
        padding: 4px 20px !important;

        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: var(--shadow);

        border: 0;

        background-color: var(--white);
        color: var(--green);

        width: ${(props) => props.width || "auto"} !important;

        transition: 200ms;

        & svg {
            margin-right: 10px !important;
        }

        &:hover {
            text-decoration: none;
            transform: scale(1.1);
        }
    }
`;

const StyledButtonsGrid = Styled(ButtonsGrid)`
    margin: 0!important;
`;

const Index = ({ text, textButton, image, url }) => {
    const history = useHistory();

    return (
        <Container>
            <div>
                <Img
                    src={image}
                    className="border-0 shadow-none"
                    max-height="400px"
                />
                <p>{text}</p>
            </div>

            <StyledButtonsGrid>
                <button
                    onClick={() => {
                        window.scrollTo(0, 0);
                        history.push(url);
                    }}
                >
                    <FaArrowRight />
                    {textButton}
                </button>
            </StyledButtonsGrid>
        </Container>
    );
};

export default Index;
