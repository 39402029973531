import React from "react";

import { Container } from "./styles";

import Img from "../../Img";
import Slider from "../../Slider";

import useHomeCarousel from "../Home/useHome";

function HomeCarousel() {
    const { carousel } = useHomeCarousel();

    return (
        <Container>
            <Slider>
                {carousel.map((file, index) => (
                    <div key={index}>
                        <Img src={file.preview} />
                    </div>
                ))}
            </Slider>
        </Container>
    );
}

export default HomeCarousel;
