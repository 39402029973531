import React from "react";
import { Row, Col } from "react-bootstrap";

import image1 from "../../assets/Images/company/empresa1.jpg";
import image2 from "../../assets/Images/company/empresa2.jpg";
import image4 from "../../assets/Images/company/empresa4.jpg";
import image6 from "../../assets/Images/company/empresa6.jpg";
import image7 from "../../assets/Images/company/empresa7.jpg";
import image8 from "../../assets/Images/company/empresa8.jpg";

import Container from "../../components/Container";
import Img from "../../components/Img";

const Company = () => {
    return (
        <Container>
            <header>
                <h1>A empresa</h1>
            </header>
            <section>
                <div>
                    <p>
                        Há mais de vinte e cinco anos a Noordhen Brasil vem
                        fornecendo a todo o mercado brasileiro soluções em
                        produtos e sistemas de alta qualidade em todos os
                        segmentos onde atua.
                    </p>
                    <p>
                        Mais que Especialistas em Higiene Aplicada, somos uma
                        empresa voltada a atender os nossos clientes com a
                        máxima agilidade e eficiência.
                    </p>
                    <p>
                        Nossos principais valores são a satisfação, o conforto e
                        a segurança do homem, seja ele nosso colaborador,
                        parceiro, distribuidor ou consumidor final de nossos
                        produtos e serviços.
                    </p>
                </div>
                <Row>
                    <Col md="6" sm="12">
                        <Img src={image2} alt="A empresa noordhen" />
                    </Col>
                    <Col md="6" sm="12">
                        <Img
                            src={image1}
                            alt="Especialista em Higiene Aplicada"
                        />
                    </Col>
                </Row>
            </section>
            <section>
                <h2>Baseamos nossa atuação nessa responsabilidade:</h2>
                <ul>
                    <li>
                        Elegendo insumos dos mais reputados fornecedores do
                        mundo
                    </li>
                    <li>
                        Na consciência ecológica - desenvolvendo produtos
                        sustentáveis, e no tratamento e destinação de nossos
                        efluentes e resíduos
                    </li>
                    <li>
                        No rígido controle de qualidade de nossos produtos e na
                        inovação tecnológica.
                    </li>
                </ul>

                <p>
                    Nossa estrutura de operação visa oferecer ao mercado
                    produtos de elevada performance com consultoria, assistência
                    técnica e treinamento de nossos clientes, aliados à
                    utilização de modernos conceitos da tecnologia da
                    informação.
                </p>
                <p>
                    A Noordhen Brasil, vem constantemente buscando soluções em
                    sua planta de produção, otimizando-a sempre a fim de agregar
                    valor aos seus negócios e a todos os seus clientes.
                </p>
                <p>
                    Somos provedores de produtos químicos e sistemas em todos os
                    segmentos de higiene existentes.
                </p>
                <p>
                    Buscamos constantemente criar nova forma de relacionamento
                    com o cliente desenvolvendo alternativas a medida de suas
                    necessidades.
                </p>
                <p>
                    Trabalhamos constantemente em nossos processos internos
                    visando melhor performance e eficiência, buscando a redução
                    de custos em toda a nossa cadeia, refletindo com essa
                    atitude uma série de benefícios para nossos clientes.
                </p>
                <p>
                    A proximidade com o nosso mercado e os nossos parceiros
                    permite-nos impulsionar os negócios, pois se torna mais ágil
                    o atendimento e desenvolvimento de produtos para
                    necessidades regionais.
                </p>
                <p>
                    Produtos desenvolvidos para tais atividades passam então a
                    agregar o nosso portifólio e desta forma podem ser
                    adquiridos por parcerias de outras regiões.
                </p>
            </section>

            <section>
                <h2>Planta de Produção</h2>
                <p>
                    Nossa fábrica em Tremembé-SP possui um total de 4 unidades
                    de produção para produtos líquidos (três plantas) e 1 para
                    produtos sólidos (pós industriais).
                </p>
                <p>
                    Nossas unidades de produção vêm sendo constantemente
                    remodeladas, incorporando-se novas tecnologias e meios de
                    gestão, afim de manter-nos atualizados e oferecer maior
                    qualidade aos nossos clientes.
                </p>
                <p>
                    A sustentabilidade faz parte de nossa estratégia de
                    negócios, norteando as nossas decisões e práticas,
                    equilibrando aspectos econômicos, sociais e ambientais em
                    todos os nossos processos de fabricação.
                </p>

                <h3>Registros Oficiais</h3>

                <p>
                    Os produtos Noordhen Brasil possuem registros junto ao
                    Ministério da Saúde e laudos de comprovação de eficácia
                    emitidos por laboratórios credenciados.
                </p>
            </section>
            <section>
                <h2>O Padrão de Qualidade Noordhen</h2>
                <p>
                    Produtos e atendimento de qualidade não se conseguem por
                    acaso.
                </p>

                <p>
                    Uma formulação eficiente e de custo competitivo requer
                    planejamento, empenho, compromisso e responsabilidade, com
                    séria supervisão em todas as fases que envolvam seu
                    processamento, do insumo ao produto acabado.
                </p>

                <p>
                    É muito bom encontrarmos produtos eficientes, e melhor ainda
                    quando se sabe da existência do mais alto rigor na
                    elaboração deste produto.
                </p>
                <p>
                    A Noordhen Brasil busca suas matérias-primas nos mais
                    reputados fabricantes do mundo, mas mesmo assim não deixa de
                    examinar rigorosamente cada lote de material recebido para
                    assegurar-se de suas especificações.
                </p>
                <p>
                    Os clientes da Noordhen Brasil podem estar certos que
                    utilizam produtos de qualidade, acima de tudo
                </p>

                <Row>
                    <Col md="6" sm="12">
                        <Img src={image4} alt="Padrão de Qualidade Noordhen" />
                    </Col>
                    <Col md="6" sm="12">
                        <Img src={image6} alt="Produtos Noordhen" />
                    </Col>
                </Row>
            </section>
            <section>
                <h2>Pesquisa e Aplicação</h2>
                <Img
                    src={image7}
                    alt="pesquisa e aplicação noordhen"
                    max-width="470px"
                    className="float-left"
                    margin="1.5rem"
                />
                <p>
                    A busca incessante por qualidade e performance, em todos os
                    nossos processos e produtos, reflete diretamente no sucesso
                    empresarial de nossos clientes.
                </p>
                <p>
                    Dessa forma, ao colaborarmos no crescimento dos negócios de
                    nossos clientes promovemos o desenvolvimento sustentável do
                    nosso negócio sempre.
                </p>
                <p>
                    Nas atividades de Pesquisa, a empresa dispõe de moderno
                    laboratório destinado aos estudos nas mais diversas
                    aplicações.
                </p>
                <p>
                    A filosofia empresarial de investir no desenvolvimento
                    interno de tecnologia tem permitido à Noordhen Brasil
                    oferecer soluções sempre que necessário.
                </p>
                <p>
                    Todos os produtos fabricados e comercializados pela empresa
                    tem como característica básica a sofisticação tecnológica na
                    seleção dos componentes de sua formulação.
                </p>
                <p>
                    Por representarem produtos importantes na qualidade de vida,
                    o marketing da empresa tem como base três aspectos
                    essenciais:
                </p>
                <ul>
                    <li>Inovação Tecnológica</li>
                    <li>Controle de Qualidade e</li>
                    <li>Assistência Técnica.</li>
                </ul>
                <p>
                    O contato técnico com nossos consumidores é realizado por
                    profissionais especializados e permite não só a otimização
                    dos produtos como a produção específica que atenda às
                    necessidades especiais, além da orientação técnica
                    permanente.
                </p>
            </section>

            <section>
                <h2>Sustentabilidade</h2>

                <p>
                    A Noordhen Brasil dedica tempo e recursos para promover a
                    inovação direcionada principalmente aos produtos de mercado
                    que apresentam alto potêncial para redução de emissões,
                    tanto em formulações quanto nos materiais utilizados na
                    embalagem de seus produtos. Essa preocupação se mostra
                    também em todas as áreas de atuação da empresa.
                </p>
            </section>
            <section>
                <header>
                    <h2>Logística e Transportes</h2>
                </header>
                <Img
                    src={image8}
                    alt="logística noordhen"
                    max-width="470px"
                    className="float-right"
                    margin="1.5rem"
                />
                <p>
                    Como parte do comprometimento da qualidade de nossos
                    produtos, tempo e recursos são constantemente dedicados a
                    pesquisar melhores formas de fabricação e na logística de
                    entrega de nossos produtos.
                </p>
            </section>
        </Container>
    );
};

export default Company;
