import React from "react";
import { Link } from "react-router-dom";

import { FiPlusCircle } from "react-icons/fi";

import Container from "../../../components/Admin/Container";

import Table, { ResponsiveTable } from "../../../components/Table";

import useAccessoriesAndEquipment from "./useAccessoriesAndEquipment";

function AccessoriesAndEquipment() {
    useAccessoriesAndEquipment();

    return (
        <Container pageTitle="Acessórios e Equipamentos">
            <div className="grid-add">
                <Link to="/ctop/acessorio-e-equipamento" className="add">
                    <FiPlusCircle />
                </Link>
            </div>
            <ResponsiveTable>
                <Table id="accessories-and-equipment-table"></Table>
            </ResponsiveTable>
        </Container>
    );
}

export default AccessoriesAndEquipment;
