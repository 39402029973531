import styled from "styled-components";
import { Button } from "react-bootstrap";

const Index = styled(Button)`
    font-family: var(--font-title);
    font-weight: 600;
    font-size: 2.3rem !important;
    padding: 4px 20px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: var(--shadow);

    width: ${(props) => props.width || "auto"} !important;

    & svg {
        margin-right: 10px !important;
    }
`;

export default Index;
