import React from "react";
import Routes from "./routes/routes";
import { Worker } from "@phuocng/react-pdf-viewer";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Root as RootLoading } from "./hooks/useLoading";

function App() {
    return (
        <>
            <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@${process.env.REACT_APP_WORKER_VERSION}/build/pdf.worker.min.js`}
            ></Worker>
            <RootLoading />
            <Routes />
        </>
    );
}

export default App;
