import React from "react";
import { Row, Col } from "react-bootstrap";

import Img from "../../../components/Img";
import CDOCContainer from "../Container";

import useGraphicMaterial from "./useGraphicMaterial";

import { Container } from "./styles";

function GraphicMaterial({ client }) {
    const { graphicMaterials, handleSelectDocument } =
        useGraphicMaterial(client);

    return (
        <CDOCContainer {...{ client, title: "Material Gráfico" }}>
            <Container>
                <Row>
                    {graphicMaterials.map((graphicMaterial) => (
                        <Col
                            md="4"
                            sm="12"
                            key={graphicMaterial.id}
                            className="d-flex justify-content-center"
                        >
                            <div
                                className="document"
                                onClick={() => {
                                    handleSelectDocument(
                                        graphicMaterial.arquivo,
                                        graphicMaterial.nome
                                    );
                                }}
                            >
                                <Img
                                    src={graphicMaterial.imagem}
                                    className="border-0 shadow-none"
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </CDOCContainer>
    );
}

export default GraphicMaterial;
