import React from "react";
import Slider from "react-slick";

const Index = ({ children }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        pauseOnHover: false,
    };

    return <Slider {...settings}>{children}</Slider>;
};

export default Index;
