import React from "react";
import Styled from "styled-components";

import Ecobrite from "../SettingsPMEcobrite";
import Solution from "../SettingsPMSolution";
import ProfessionalProducts from "../SettingsPMPP";
import Pratic from "../SettingsPMPratic";

const Container = Styled.div`

`;

function Index() {
    return (
        <Container>
            <h1>Produtos e Mercado</h1>
            <div className="row">
                <Ecobrite />
                <Solution />
            </div>
            <ProfessionalProducts />
            <Pratic />
        </Container>
    );
}

export default Index;
