import React from "react";

import { Container, PopupContainer, Button } from "./styles";

function HomePopup({ show, onClick }) {
    return (
        <Container {...{ show }}>
            <PopupContainer>
                <div
                    className="popup-inner-container"
                    id="popup-container"
                ></div>
                <Button {...{ onClick }}>Fechar</Button>
            </PopupContainer>
        </Container>
    );
}

export default HomePopup;
