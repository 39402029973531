import React, { useState, useEffect } from "react";
import Styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FaPaperPlane, FaTrash } from "react-icons/fa";
import { Row } from "react-bootstrap";

import Container from "../../../components/Admin/Container";
import Input, { File, Switch } from "../../../components/Input";
import Button from "../../../components/Button";
import Form from "../../../components/Form";
import ButtonsGrid from "../../../components/ButtonsGrid";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";

import Notification, { Error } from "../../../modules/Notifications";
import Confirm from "../../../modules/AlertConfirm";

const NewFiles = Styled(Row)`
    display: flex;
    justify-content: center;
    margin-top: 1rem!important;
`;

const Index = (props) => {
    const { id } = props.match.params;

    const [data, setData] = useState({});

    const [imageNew, setImageNew] = useState({});

    const [pdfNew, setPdfNew] = useState({});

    const history = useHistory();

    const { loading } = useLoading();

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();

            const response = await api.get(`/noticias/${id}`);

            setImageNew({
                preview: response.data.imagem,
            });

            setPdfNew({ preview: response.data.noticia });

            setData(response.data);

            loading(idLoading);
        };

        if (id) show();
    }, [id, loading]);

    const handleValidate = () => {
        if (!data.titulo) {
            return { status: false, message: "Título é obrigatório" };
        }

        if (!data.data) {
            return { status: false, message: "Data é obrigatório" };
        }

        if (!imageNew.file && !id) {
            return {
                status: false,
                message: "Imagem da notícia é obrigatório",
            };
        }

        if (!pdfNew.file && !id) {
            return { status: false, message: "PDF da notícia é obrigatório" };
        }

        return { status: true };
    };

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);

        const response = await api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    };

    const uploadFiles = async () => {
        try {
            const img = imageNew.file;

            data.imagem = "";

            if (img) {
                const imgNotiResponse = await uploadFile(img);
                data.imagem = `${api.defaults.baseURL}${imgNotiResponse.url}`;
            }

            if (!imageNew.preview) {
                data.imagem = "";
            }

            const pdf = pdfNew.file;

            if (pdf) {
                const pdfNotiResponse = await uploadFile(pdf);
                data.noticia = `${api.defaults.baseURL}${pdfNotiResponse.url}`;
            }

            if (!pdfNew.preview) {
                data.noticia = "";
            }
        } catch (error) {
            console.log(error);
            Error(error);
        }
    };

    const create = async () => {
        const idLoading = loading();
        try {
            await api.post("/noticias", { ...data, ativo: true });

            Notification("success", "Notícia cadastrada");

            history.push("/ctop/noticias");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const upload = async () => {
        const idLoading = loading();

        try {
            await api.put(`/noticias/${id}`, data);

            Notification("success", "Notícia atualizada");

            loading(idLoading);

            history.push("/ctop/noticias");
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const destroy = async (event) => {
        event.preventDefault();

        Confirm(
            "Remover Produto",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();
                try {
                    await api.delete(`/noticias/${id}`);

                    Notification("success", "Notícia removido");

                    loading(idLoading);

                    history.push("/ctop/noticias");
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    const submit = async (event) => {
        event.preventDefault();

        const validate = handleValidate();
        if (!validate.status) {
            return Notification("warning", validate.message);
        }

        await uploadFiles();

        if (!id) {
            return await create();
        }

        return upload();
    };

    return (
        <Container
            pageTitle={`${
                !props.match.params.id ? "Cadastrar" : "Editar"
            } Notícia`}
        >
            <Form className="col" onSubmit={submit}>
                <Row className="justify-center align-items-center">
                    <Input
                        className="col"
                        type="text"
                        label="Título:"
                        value={data.titulo}
                        onChange={(event) => {
                            setData({ ...data, titulo: event.target.value });
                        }}
                    />
                    <Input
                        className="col"
                        label="Data da Notícia:"
                        type="date"
                        value={data.data}
                        onChange={(event) => {
                            setData({ ...data, data: event.target.value });
                        }}
                    />
                    {id && (
                        <Row className="col-12">
                            <Switch
                                className="col"
                                type="checkbox"
                                label="Notícia Ativa"
                                id="is-actived"
                                checked={data.ativo}
                                onChange={(event) => {
                                    setData({
                                        ...data,
                                        ativo: event.target.checked,
                                    });
                                }}
                            />
                        </Row>
                    )}
                </Row>

                <NewFiles>
                    <File
                        className="col"
                        label="Imagem da Notícia:"
                        width="200"
                        height="200px"
                        accept=".jpg, .png"
                        file={imageNew}
                        onUpload={(files) => {
                            const file = files[0];

                            setImageNew({
                                file,
                                preview: URL.createObjectURL(file),
                            });
                        }}
                        onDeleteFile={() => {
                            setImageNew({});
                        }}
                    />
                    <File
                        className="col"
                        label="Notícia:"
                        width="200"
                        height="200px"
                        accept=".pdf"
                        fileType="pdf"
                        file={pdfNew}
                        onUpload={(files) => {
                            const file = files[0];

                            setPdfNew({
                                file,
                                preview: URL.createObjectURL(file),
                            });
                        }}
                        onDeleteFile={() => {
                            setPdfNew({});
                        }}
                    />
                </NewFiles>
                <ButtonsGrid>
                    <Button type="submit" variant="success">
                        <FaPaperPlane />
                        Enviar
                    </Button>
                    {id && (
                        <Button variant="outline-danger" onClick={destroy}>
                            <FaTrash />
                            Remover
                        </Button>
                    )}
                </ButtonsGrid>
            </Form>
        </Container>
    );
};

export default Index;
