import styled from "styled-components";

export const Container = styled.div`
    ul,
    li {
        margin: 0 !important;
    }
`;

export const Header = styled.header`
    margin-bottom: 1rem !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding: 1rem 0 !important;

    h2 {
        margin: 0 !important;
    }

    div {
        display: flex;
        align-items: flex-start;
        button {
            font-size: 1.5rem !important;
            height: fit-content;
            margin-right: 1rem !important;
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
`;
