import Styled from "styled-components";

export const Main = Styled.main`
    nav {
        display: flex;
        margin-bottom: 2rem!important;

        button {
            margin-left: 1rem!important;
        }

        button:first-child {
            margin-left: 0rem!important;
        }

        @media (max-width: 750px) {
            & {
                padding: 2rem!important;
            }

            & button {
                width: 100%!important;
                margin-bottom: 1rem!important;
                margin-left: 0rem!important;
            }
            
            & button:last-child {
                margin-bottom: 0rem!important;
            }
        }
        
    }
`;

export const NavItem = Styled.div.attrs({
    className: "col-sm-12 col-md-3",
})`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem!important;

    button {
        font-size: 1.5rem!important;
    }
`;

export const SectionNav = Styled.section`
    display: ${(props) => (props.show ? "block" : "none")};
`;
