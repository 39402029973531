import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import notification, {
    Error as notificationError,
} from "../../../modules/Notifications";
import confirm from "../../../modules/AlertConfirm";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";

function useRelease() {
    const [nome, setNome] = useState("");
    const [imagem, setImagem] = useState({});
    const [arquivo, setArquivo] = useState({});

    const { loading } = useLoading();

    const { id } = useParams();

    const history = useHistory();

    const show = useCallback(() => {
        async function show() {
            const idLoading = loading();

            try {
                const response = await api.get(`/lancamentos/${id}`);

                const data = response.data;

                setNome(data.nome);

                const imagem = {
                    id: response.data.imagem.id,
                    preview: `${api.defaults.baseURL}${response.data.imagem.url}`,
                };

                setImagem(imagem);

                const arquivo = {
                    id: response.data.arquivo.id,
                    preview: `${api.defaults.baseURL}${response.data.arquivo.url}`,
                };

                setArquivo(arquivo);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        show();
    }, [id, loading]);

    useEffect(() => {
        if (id) show();
    }, [show, id]);

    function handleOnChangeNome({ target }) {
        const { value } = target;

        setNome(value);
    }

    function handleOnChangeImagem(files) {
        const file = files[0];

        if (imagem.id) {
            confirm(
                "Alterar Imagem",
                "Tem certeza que deseja alterar ?",
                async () => {
                    const idLoading = loading();

                    try {
                        await api.delete(`/upload/files/${imagem.id}`);

                        const response = await uploadFile(file);

                        await api.put(`/lancamentos/${id}`, {
                            imagem: response,
                        });

                        show();

                        notification("success", "Imagem alterada");

                        loading(idLoading);
                    } catch (error) {
                        notificationError(error);

                        loading(idLoading);
                    }
                }
            );
        } else {
            setImagem({
                file,
                preview: URL.createObjectURL(file),
            });
        }
    }

    function handleOnDeleteImagem() {
        if (imagem.id) {
            notification("warning", "Não é possivel remover");
        } else {
            setImagem({});
        }
    }

    function handleOnChangeArquivo(files) {
        const uploadedFile = files[0];
        if (arquivo.id) {
            confirm(
                "Alterar Arquivo",
                "Tem certeza que deseja alterar ?",
                async () => {
                    const idLoading = loading();

                    try {
                        await api.delete(`/upload/files/${arquivo.id}`);

                        const response = await uploadFile(uploadedFile);

                        await api.put(`/lancamentos/${id}`, {
                            arquivo: response,
                        });

                        show();

                        notification("success", "Arquivo alterado");

                        loading(idLoading);
                    } catch (error) {
                        notificationError(error);

                        loading(idLoading);
                    }
                }
            );
        } else {
            setArquivo({
                file: uploadedFile,
                preview: URL.createObjectURL(uploadedFile),
            });
        }
    }

    function handleOnDeleteArquivo() {
        if (arquivo.id) {
            notification("warning", "Não é possivel remover");
        } else {
            setArquivo({});
        }
    }

    function handleValidate() {
        if (!nome) {
            return { status: false, message: "Nome é obrigatório" };
        }

        if (Object.keys(imagem).length === 0) {
            return { status: false, message: "Imagem é obrigatório" };
        }

        if (Object.keys(arquivo).length === 0) {
            return { status: false, message: "Arquivo é obrigatório" };
        }

        return { status: true };
    }

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);

        const response = await api.post("/upload", data);

        loading(idLoading);

        return response.data[0];
    };

    async function create(data) {
        const idLoading = loading();

        try {
            await api.post("/lancamentos", data);

            notification("success", "Lançamentos cadastrado");

            loading(idLoading);
        } catch (error) {
            notificationError(error);

            loading(idLoading);
        }
    }

    async function update(id, data) {
        const idLoading = loading();

        try {
            await api.put(`/lancamentos/${id}`, data);

            notification("success", "Marca homologada alterado");

            loading(idLoading);
        } catch (error) {
            notificationError(error);

            loading(idLoading);
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        const idLoading = loading();

        const validate = handleValidate();
        if (!validate.status) {
            return notification("warning", validate.message);
        }

        if (!imagem.id) {
            var uploadedImagem = await uploadFile(imagem.file);
        }

        if (!arquivo.id) {
            var uploadedArquivo = await uploadFile(arquivo.file);
        }

        if (!id) {
            await create({
                nome,
                imagem: uploadedImagem,
                arquivo: uploadedArquivo,
            });
        } else {
            await update(id, { nome });
        }

        loading(idLoading);

        history.push("/ctop/lancamentos");
    }

    function destroy() {
        confirm(
            "Remover Lançamento",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/lancamentos/${id}`);

                    notification("success", "Lançamento removido");

                    history.push("/ctop/lancamentos");

                    loading(idLoading);
                } catch (error) {
                    notificationError(error);

                    loading(idLoading);
                }
            }
        );
    }

    return {
        nome,
        imagem,
        arquivo,
        handleOnChangeNome,
        handleOnChangeArquivo,
        handleOnDeleteArquivo,
        handleOnChangeImagem,
        handleOnDeleteImagem,
        handleSubmit,
        destroy,
    };
}

export default useRelease;
