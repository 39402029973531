import React from "react";
import {
    ScheduleComponent,
    Inject,
    Month,
} from "@syncfusion/ej2-react-schedule";
import { removeClass } from "@syncfusion/ej2-base";
import { L10n, loadCldr } from "@syncfusion/ej2-base";
import gregorian from "cldr-data/main/pt/ca-gregorian.json";
import numbers from "cldr-data/main/pt/numbers.json";
import timeZoneNames from "cldr-data/main/pt/timeZoneNames.json";
import numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import weekData from "cldr-data/supplemental/weekData.json";

import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-schedule/styles/material.css";

import { Container } from "./styles";

import CDOCContainer from "../Container";

import config from "./config.json";

import useEvents from "./useEvents";

loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);

L10n.load(config);

function Events({ client }) {
    const { events, eventClick, cancelEvent } = useEvents(client);

    function eventTemplate({ Subject }) {
        return <div className="e-subject e-text-center">{Subject}</div>;
    }

    return (
        <CDOCContainer {...{ client, title: "Eventos" }}>
            <Container>
                <ScheduleComponent
                    currentView="Month"
                    locale="pt"
                    eventSettings={{
                        dataSource: events,
                        template: eventTemplate,
                    }}
                    // actionComplete={handleActionComplete}
                    // allowMultiCellSelection={true}
                    // allowMultiRowSelection={true}
                    // allowResizing={true}
                    // allowDragAndDrop={true}
                    cellClick={cancelEvent}
                    cellDoubleClick={cancelEvent}
                    onPopupOpen={cancelEvent}
                    eventClick={eventClick}
                    // moreEventsClick={onPopupOpen}
                    renderCell={(args) => {
                        if (
                            args.elementType === "dateHeader" ||
                            args.elementType === "monthCells"
                        ) {
                            removeClass(args.element.childNodes, "e-navigate");
                        }
                    }}
                >
                    <Inject services={[Month]} />
                </ScheduleComponent>
            </Container>
        </CDOCContainer>
    );
}

export default Events;
