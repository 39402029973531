import React from 'react';

const ThanksMessage = () => {
    return (
        <React.Fragment>
            <div className="container">
                <h1>
                    Agradecemos imensamente o seu contato.
                </h1>
                <p>
                    Este email será encaminhado internamente à área competente, que entrará em contato o mais breve possível.
                </p>
            </div>
        </React.Fragment>
    )
}

export default ThanksMessage;