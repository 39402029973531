import React from "react";
import Menu from "../Menu";
import Main from "../Main";

const Index = ({ children, pageTitle }) => {
    document.title = `Noordhen Brasil - ${pageTitle}`;
    return (
        <>
            <Menu />
            <Main>
                <header>
                    <h1>{pageTitle}</h1>
                </header>
                {children}
            </Main>
        </>
    );
};

export default Index;
