import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FiFolder, FiPlusCircle, FiCheck, FiX } from "react-icons/fi";
import { FaClipboardList } from "react-icons/fa";

import Container from "../../../components/Admin/Container";
import Table, { ResponsiveTable } from "../../../components/Table";
import Button from "../../../components/Button";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";

import DataTables from "../../../modules/DataTables";
import { Error } from "../../../modules/Notifications";

const ShowClients = () => {
    const history = useHistory();

    const { loading } = useLoading();

    const callbackDraw = useCallback(() => {
        const handleEdit = (id) => {
            history.push(`/ctop/cliente/${id}`);
        };

        const cdocEls = document.querySelectorAll(".cdoc");

        for (const cdocEl of cdocEls) {
            ReactDOM.render(
                cdocEl.dataset.value === "true" ? (
                    <FiCheck color="green" />
                ) : (
                    <FiX color="red" />
                ),
                document.getElementById(cdocEl.id)
            );
        }

        const pedidosEls = document.querySelectorAll(".pedidos");

        for (const pedidosEl of pedidosEls) {
            ReactDOM.render(
                pedidosEl.dataset.value === "true" ? (
                    <FiCheck color="green" />
                ) : (
                    <FiX color="red" />
                ),
                document.getElementById(pedidosEl.id)
            );
        }

        const editarEls = document.querySelectorAll(".editar");

        for (const editarEl of editarEls) {
            ReactDOM.render(
                <FiFolder
                    className="cursor-pointer"
                    onClick={() => {
                        handleEdit(editarEl.dataset.id);
                    }}
                />,
                document.getElementById(editarEl.id)
            );
        }
    }, [history]);

    useEffect(() => {
        const index = async () => {
            const idLoading = loading();

            try {
                const response = await api.get("/clientes?_limit=-1");

                let serializedData = response.data.map((item) => {
                    const { id: ID, Nome, CDOC, Pedidos } = item;

                    return {
                        ID,
                        Nome,
                        CDOC: `<div class="cdoc" data-value="${CDOC}" id="cdoc-${ID}"></div>`,
                        Pedidos: `<div class="pedidos" data-value="${Pedidos}" id="pedidos-${ID}"></div>`,
                        Abrir: `<div class="editar" data-id="${ID}" id="editar-${ID}"></div>`,
                    };
                });

                DataTables(
                    "#clients-table",
                    serializedData,
                    [
                        { title: "Nome", data: "Nome" },
                        { title: "CDOC", data: "CDOC" },
                        { title: "Pedidos", data: "Pedidos" },
                        { title: "Abrir", data: "Abrir" },
                    ],
                    () => {
                        callbackDraw();
                    }
                );

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        index();
    }, [callbackDraw, loading]);

    return (
        <Container pageTitle="Listar Clientes">
            <div className="grid-add">
                <Link to="/ctop/cliente" className="add">
                    <FiPlusCircle />
                </Link>
                <Button
                    variant="success"
                    onClick={() => {
                        history.push("/ctop/logs-cdoc");
                    }}
                >
                    <FaClipboardList />
                    Relatório de Documentos
                </Button>
            </div>
            <ResponsiveTable>
                <Table id="clients-table"></Table>
            </ResponsiveTable>
        </Container>
    );
};

export default ShowClients;
