import axios from "axios";

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Authorization: localStorage.getItem("token"),
    },
});

export const publicRequest = {
    headers: {
        Authorization: "",
    },
};

export default api;
