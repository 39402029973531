import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { FaFileExport } from "react-icons/fa";

import Container from "../../../components/Admin/Container";
import Button from "../../../components/Button";
import Input, { Select } from "../../../components/Input";
import Table, { ResponsiveTable } from "../../../components/Table";

import { Error } from "../../../modules/Notifications";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";
import htmlToPdfApi from "../../../service/html-to-pdf-api";

const Index = () => {
    const [data, setData] = useState([]);

    const [filtros, setFiltros] = useState({});

    const { loading } = useLoading();

    const documents = {
        1: "Literatura",
        2: "FISPQ",
        3: "Laudos",
        4: "Material publicatário de apoio",
    };

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                let endPoint = "/log-cdocs?_limit=-1";

                if (filtros.client) {
                    endPoint =
                        endPoint + `&cliente.Nome_contains=${filtros.client}`;
                }

                if (filtros.product) {
                    endPoint =
                        endPoint +
                        `&produto.Titulo_contains=${filtros.product}`;
                }

                if (filtros.documento) {
                    endPoint = endPoint + `&cdoc=${filtros.documento}`;
                }

                if (filtros.criadoEmInicio) {
                    endPoint =
                        endPoint + `&created_at_gte=${filtros.criadoEmInicio}`;
                }

                if (filtros.criadoEmFinal) {
                    endPoint =
                        endPoint +
                        `&created_at_lte=${
                            filtros.criadoEmFinal + "T23:59:59"
                        }`;
                }

                const response = await api.get(endPoint);

                setData(response.data);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [filtros, loading]);

    const ExportButton = Styled(Button)`
        margin: 2rem!important;
    `;

    async function exportTable() {
        const idLoading = loading();
        try {
            const html = document.querySelector("#table-logs-cdoc").innerHTML;

            const name = `logs-documentos-${new Date().toLocaleDateString()}`;

            const response = await htmlToPdfApi.post("/file/convert", {
                html,
                name,
            });

            window.open(
                `${htmlToPdfApi.defaults.baseURL}/file/${response.data._id}`
            );

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    return (
        <Container pageTitle="Relatório de Documentos">
            <Row className="d-flex flex-row-reverse">
                <ExportButton onClick={exportTable}>
                    <FaFileExport />
                    Exportar
                </ExportButton>
            </Row>
            <Row>
                <Row className="col-md-7 col-sm-12">
                    <Col sm="12" md="4">
                        <Input
                            type="text"
                            label="Cliente"
                            value={filtros.client}
                            onChange={(event) =>
                                setFiltros({
                                    ...filtros,
                                    client: event.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col sm="12" md="4">
                        <Input
                            type="text"
                            label="Produto"
                            value={filtros.product}
                            onChange={(event) =>
                                setFiltros({
                                    ...filtros,
                                    product: event.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col sm="12" md="4">
                        <Select
                            label="Documento"
                            value={filtros.documento}
                            onChange={(event) =>
                                setFiltros({
                                    ...filtros,
                                    documento: event.target.value,
                                })
                            }
                        >
                            <option value="">Selecione...</option>
                            <option value="1">Literatura</option>
                            <option value="2">FISPQ</option>
                            <option value="3">Laudos</option>
                            <option value="4">
                                Material publicatário de apoio
                            </option>
                        </Select>
                    </Col>
                </Row>

                <Row className="col-md-5 col-sm-12">
                    <Col sm="12" md="6">
                        <Input
                            type="date"
                            label="Data início"
                            value={filtros.criadoEmInicio}
                            onChange={(event) =>
                                setFiltros({
                                    ...filtros,
                                    criadoEmInicio: event.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col sm="12" md="6">
                        <Input
                            type="date"
                            label="Data final"
                            value={filtros.criadoEmFinal}
                            onChange={(event) =>
                                setFiltros({
                                    ...filtros,
                                    criadoEmFinal: event.target.value,
                                })
                            }
                        />
                    </Col>
                </Row>
            </Row>
            <ResponsiveTable id="table-logs-cdoc">
                {data.length > 0 && (
                    <Table>
                        <thead>
                            <tr>
                                <th>Cliente</th>
                                <th>Login</th>
                                <th>Produto</th>
                                <th>Documento</th>
                                <th>Criado em</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.cliente.Nome}</td>
                                    <td>{item.cliente.login}</td>
                                    <td>
                                        {item.produto
                                            ? item.produto.Titulo
                                            : "-"}
                                    </td>
                                    <td>
                                        {item.cdoc === 6
                                            ? item.titulo
                                            : documents[item.cdoc]}
                                    </td>
                                    <td>
                                        {new Date(
                                            item.created_at
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </ResponsiveTable>
        </Container>
    );
};

export default Index;
