import React, { useState } from "react";
import {
    FaBars,
    FaHome,
    FaSignOutAlt,
    FaBox,
    FaEnvelope,
    FaPuzzlePiece,
    FaCalendarDay,
} from "react-icons/fa";
import { MdNewReleases } from "react-icons/md";
import { AiOutlineAntDesign } from "react-icons/ai";

import useMenu from "./useMenu";

import { Container } from "./styles";

import Aside from "../../Admin/Aside";
import Main from "../../Admin/Main";

function Menu({ client }) {
    const [navVisible, setNavVisible] = useState("navNotVisible");

    const {
        handleSignOut,
        handleOnClickProduct,
        handleOnClickContact,
        handleOnClickHome,
        handleOnClickReleases,
        handleOnClickAccessoriesAndEquipments,
        handleOnClickGraphicMaterial,
        handleOnClickEvents,
    } = useMenu(client);

    return (
        <Container>
            <Aside>
                <div
                    className="toggle-menu cursor-pointer"
                    onClick={() => {
                        if (navVisible === "navNotVisible") {
                            return setNavVisible("navVisible");
                        }

                        return setNavVisible("navNotVisible");
                    }}
                >
                    <FaBars />
                    <span>Menu</span>
                </div>
                <nav className={navVisible}>
                    <strong onClick={handleOnClickHome}>
                        <FaHome />
                        <span>Home</span>
                    </strong>

                    <strong onClick={handleOnClickReleases}>
                        <MdNewReleases />
                        <span>Lançamentos</span>
                    </strong>

                    <strong onClick={handleOnClickProduct}>
                        <FaBox />
                        <span>Produtos</span>
                    </strong>

                    <strong onClick={handleOnClickAccessoriesAndEquipments}>
                        <FaPuzzlePiece />
                        <span>Acessórios e Equipamentos</span>
                    </strong>

                    <strong onClick={handleOnClickGraphicMaterial}>
                        <AiOutlineAntDesign />
                        <span>Material Gráfico</span>
                    </strong>

                    <strong onClick={handleOnClickEvents}>
                        <FaCalendarDay />
                        <span>Eventos</span>
                    </strong>

                    <strong onClick={handleOnClickContact}>
                        <FaEnvelope />
                        <span>Contato</span>
                    </strong>

                    <strong onClick={handleSignOut}>
                        <FaSignOutAlt />
                        <span>Sair</span>
                    </strong>
                </nav>
            </Aside>
            <Main id="cdoc-main"></Main>
        </Container>
    );
}

export default Menu;
