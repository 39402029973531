const Index = {
  "Auth.form.error.email.provide":
    "Por favor entre com seu e-mail ou nome de usuário",
  "Auth.form.error.password.provide": "Por favor entre com sua senha",
  "Auth.form.error.invalid": "Identificador ou senha inválidos",
  "Auth.form.error.username.taken": "Nome de usuário já cadastrado",
  "Auth.form.error.email.taken": "E-Mail já cadastrado",
};

export default Index;
