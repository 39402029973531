import React from "react";
import Container from "../../components/Container";
import ThanksMessage from "../../components/ThanksMessage";

const ThanksPage = () => {
    return (
        <Container>
            <ThanksMessage />
        </Container>
    );
};

export default ThanksPage;
