import styled from "styled-components";

const Index = styled.img`
    width: 100%;
    height: auto;
    max-width: ${(props) => props["max-width"] || "auto"} !important;
    max-height: ${(props) => props["max-height"] || "auto"} !important;
    min-width: ${(props) => props["min-width"] || "auto"} !important;
    min-height: ${(props) => props["min-height"] || "auto"} !important;
    border: 2px solid var(--gray-5);
    box-shadow: var(--shadow);
    margin: ${(props) => props.margin || "0"}!important;

    @media (max-width: 750px) {
        max-width: ${(props) => props["sm-max-width"] || "auto"} !important;
        max-height: ${(props) => props["sm-max-height"] || "auto"} !important;
        min-width: ${(props) => props["sm-min-width"] || "auto"} !important;
        min-height: ${(props) => props["sm-min-height"] || "auto"} !important;
    }
`;

export default Index;
