import React from "react";
import Styled from "styled-components";

import Container from "../../components/Container";
import Img from "../../components/Img";

import LogoGS from "../../assets/Images/logo-gs.png";
import Image from "../../assets/Images/gs/image.png";
import Image1 from "../../assets/Images/gs/image-1.png";

const Header = Styled.header`
    display: flex;
    align-items: center;
    flex-direction: column;

    & img {
        margin: 0 1rem 1rem 0!important;
    }
`;

const Section = Styled.section`
    margin-top: 1rem!important;
`;

const Index = () => {
    return (
        <Container>
            <Header>
                <Img
                    src={LogoGS}
                    alt="Logo gs noordhen"
                    className="border-0 shadow-none"
                    max-width="20%"
                    sm-max-width="40%"
                />
                <p>Mais uma atividade Noordhen Brasil.</p>
            </Header>
            <hr />
            <Section>
                <Img src={Image} alt="GS Solution fabrica" />
                <p>
                    Mirando as oportunidades no exigente e crescente mercado de
                    produtos verdes e veganos, a Noordhen Brasil , sempre atenta
                    às necessidades do mercado inaugura sua atividade Green
                    Solutions.
                </p>
                <p>
                    Para atender essa demanda, pesquisamos e elaboramos nossos
                    próprios extratos vegetais, sabões e blends de tensoativos
                    obtidos de fontes renováveis. Extraímos de plantas corantes
                    como a Clorofila.
                </p>
                <p>
                    Elaboramos bases especiais, além de empregar o já
                    reconhecido rígido. Controle de Qualidade em todas as etapas
                    dos processos desde a obtenção dos insumos até a entrega do
                    produto final.
                </p>
                <Img src={Image1} alt="GS Solution fabrica 1" />
            </Section>
        </Container>
    );
};

export default Index;
