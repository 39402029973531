import React from "react";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Main from "../Main";

const Index = ({ children, className, Spotlight, News }) => {
    return (
        <>
            <NavBar />
            {Spotlight && <Spotlight />}
            <Main className={className}>{children}</Main>
            {News && <News />}
            <Footer />
        </>
    );
};

export default Index;
