import styled from "styled-components";

export const Container = styled.div`
    .dropzone {
        font-size: 1rem;
        .empty-file {
            height: 42px !important;
        }
    }
`;
