import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminRoute from "./Components/PrivateRoutes/AdminRoutes";
import {
    PageNotFound404,
    Home,
    Contact,
    Company,
    ThanksPage,
    LoginAdmin,
    HomeAdmin,
    User,
    Client,
    Product,
    Aplications,
    Lines,
    Products,
    Clients,
    ProductsAndMarket,
    Users,
    LogsCDOC,
    New,
    News,
    CDOC,
    TermsOfUseCDOC,
    GsSolutions,
    AboutInputs,
    Settings,
    Cookies,
    Releases,
    Release,
    AccessoriesAndEquipment,
    AccessoryAndEquipment,
    GraphicMaterials,
    GraphicMaterial,
    Events,
    Event,
} from "../pages";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <AdminRoute path="/ctop/settings" exact component={Settings} />
            <AdminRoute path="/ctop/produtos/linhas" exact component={Lines} />
            <AdminRoute
                path="/ctop/produtos/linhas/:id"
                exact
                component={Lines}
            />
            <AdminRoute
                path="/ctop/produtos/aplicacoes"
                exact
                component={Aplications}
            />
            <AdminRoute
                path="/ctop/produtos/aplicacoes/:id"
                exact
                component={Aplications}
            />
            <AdminRoute path="/ctop/produtos" exact component={Products} />
            <AdminRoute path="/ctop/produto" exact component={Product} />

            <AdminRoute path="/ctop/produto/:id" exact component={Product} />

            <AdminRoute path="/ctop/clientes" exact component={Clients} />

            <AdminRoute path="/ctop/cliente/:id" exact component={Client} />
            <AdminRoute path="/ctop/cliente/" exact component={Client} />

            <AdminRoute path="/ctop/usuarios" exact component={Users} />
            <AdminRoute path="/ctop/usuario" exact component={User} />
            <AdminRoute path="/ctop/usuario/:id" exact component={User} />

            <AdminRoute path="/ctop/noticias" exact component={News} />
            <AdminRoute path="/ctop/noticia" exact component={New} />
            <AdminRoute path="/ctop/noticia/:id" exact component={New} />

            <AdminRoute
                path="/ctop/acessorios-e-equipamentos"
                exact
                component={AccessoriesAndEquipment}
            />
            <AdminRoute
                path="/ctop/acessorio-e-equipamento"
                exact
                component={AccessoryAndEquipment}
            />
            <AdminRoute
                path="/ctop/acessorio-e-equipamento/:id"
                exact
                component={AccessoryAndEquipment}
            />

            <AdminRoute
                path="/ctop/materiais-graficos"
                exact
                component={GraphicMaterials}
            />
            <AdminRoute
                path="/ctop/material-grafico"
                exact
                component={GraphicMaterial}
            />
            <AdminRoute
                path="/ctop/material-grafico/:id"
                exact
                component={GraphicMaterial}
            />

            <AdminRoute path="/ctop/eventos" exact component={Events} />
            <AdminRoute path="/ctop/evento" exact component={Event} />
            <AdminRoute path="/ctop/evento/:id" exact component={Event} />

            <AdminRoute path="/ctop/lancamentos" exact component={Releases} />
            <AdminRoute path="/ctop/lancamento" exact component={Release} />
            <AdminRoute path="/ctop/lancamento/:id" exact component={Release} />

            <AdminRoute path="/ctop/home" exact component={HomeAdmin} />

            <Route path="/ctop" exact component={LoginAdmin} />
            <Route path="/ctop/logs-cdoc" exact component={LogsCDOC} />

            <Route path="/central-de-documentos" exact component={CDOC} />
            <Route path="/politica-de-privacidade" exact component={Cookies} />
            <Route
                path="/produtos-e-mercado"
                exact
                component={ProductsAndMarket}
            />
            <Route path="/empresa" exact component={Company} />
            <Route
                path="/termos-de-uso-cdoc"
                exact
                component={TermsOfUseCDOC}
            />
            <Route path="/obrigado" exact component={ThanksPage} />
            <Route path="/contato" exact component={Contact} />
            <Route path="/gs-solutions" exact component={GsSolutions} />
            <Route path="/about-inputs" exact component={AboutInputs} />
            <Route path="/" exact component={Home} />
            <Route path="*" component={PageNotFound404} />
        </Switch>
    </BrowserRouter>
);

export default Routes;
