import styled from "styled-components";

export default styled.div.attrs({
    id: "loading-root",
})`
    width: 100%;
    position: fixed;
    z-index: 1000000000;
    top: 0;
`;
