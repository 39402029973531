import React, { useCallback, useState, useEffect } from "react";
import Styled from "styled-components";

import { File } from "../../components/Input";
import Button from "../../components/Button";

import Notification, { Error } from "../../modules/Notifications";
import Confirm from "../../modules/AlertConfirm";

import useLoading from "../../hooks/useLoading";

import Api from "../../service/api";

const Section = Styled.section`
    & .row {
        display: flex;
        justify-content: center;
    }
`;

function Index() {
    const [image, setImage] = useState({ file: {} });
    const [file, setFile] = useState({ file: {} });

    const { loading } = useLoading();

    const indexSettings = useCallback(() => {
        async function indexSettings() {
            const idLoading = loading();

            try {
                const response = await Api.get(
                    "/configuracoes-produtos-mercados"
                );

                const solution = response.data.find((item) => {
                    if (item.type === 1) {
                        return item;
                    }

                    return null;
                });

                let newImage = {};
                let newFile = {};
                if (solution) {
                    if (solution.object.image) {
                        newImage = {
                            file: solution.object.image,
                            id: solution.id,
                            preview: `${Api.defaults.baseURL}${solution.object.image.url}`,
                        };
                    }

                    if (solution.object.file) {
                        newFile = {
                            file: solution.object.file,
                            id: solution.id,
                            preview: `${Api.defaults.baseURL}${solution.object.file.url}`,
                        };
                    }
                }

                setImage(newImage);
                setFile(newFile);
            } catch (error) {
                console.log(error);

                // Error(error);
            } finally {
                loading(idLoading);
            }
        }

        indexSettings();
    }, [loading]);

    useEffect(() => {
        indexSettings();
    }, [indexSettings]);

    const uploadFile = async (file) => {
        const idLoading = loading();

        let data = new FormData();
        data.append(`files`, file);

        const response = await Api.post("/upload", data);

        loading(idLoading);
        return response.data[0];
    };

    async function handleSubmit() {
        const idLoading = loading();

        try {
            if (Object.keys(image.file).length === 0) {
                return Notification("warning", "Imagem Solution é obrigatório");
            }

            if (Object.keys(file.file).length === 0) {
                return Notification("warning", "PDF Solution é obrigatório");
            }

            const responseImage = await uploadFile(image.file);

            const responseFile = await uploadFile(file.file);

            await Api.post("/configuracoes-produtos-mercados", {
                type: 1,
                object: {
                    image: responseImage,
                    file: responseFile,
                },
            });

            indexSettings();
            Notification("success", "Configuração Solution cadastrada");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    }

    console.log({
        image,
        file,
    });

    return (
        <Section className="col-sm-12 col-md-6">
            <h2>Solution</h2>
            <div className="row">
                <File
                    label="Imagem*"
                    width="200"
                    accept=".jpg, .png"
                    file={image}
                    onUpload={(files) => {
                        const file = files[0];
                        if (image.id) {
                            Confirm(
                                "Alterar Imagem Solution",
                                "Tem certeza que deseja alterar ?",
                                async () => {
                                    const idLoading = loading();

                                    await Api.delete(
                                        `/upload/files/${image.file.id}`
                                    );

                                    const response = await uploadFile(file);

                                    await Api.put(
                                        `/configuracoes-produtos-mercados/${image.id}`,
                                        {
                                            object: {
                                                image: response,
                                                file: file.file,
                                            },
                                        }
                                    );

                                    indexSettings();

                                    Notification(
                                        "success",
                                        "Imagem Solution alterado"
                                    );

                                    loading(idLoading);
                                }
                            );
                        } else {
                            setImage({
                                file,
                                preview: URL.createObjectURL(file),
                            });
                        }
                    }}
                    onDeleteFile={() => {
                        if (image.id) {
                            Notification("warning", "Não é possivel remover");
                        } else {
                            setImage({});
                        }
                    }}
                />
                <File
                    label="PDF*"
                    width="200"
                    height="250px"
                    accept=".pdf"
                    fileType="pdf"
                    file={file}
                    onUpload={(files) => {
                        const file = files[0];

                        if (file.id) {
                            Confirm(
                                "Alterar PDF Solution",
                                "Tem certeza que deseja alterar ?",
                                async () => {
                                    const idLoading = loading();

                                    await Api.delete(
                                        `/upload/files/${file.file.id}`
                                    );

                                    const response = await uploadFile(file);

                                    await Api.put(
                                        `/configuracoes-produtos-mercados/${file.id}`,
                                        {
                                            object: {
                                                image: image.file,
                                                file: response,
                                            },
                                        }
                                    );

                                    indexSettings();

                                    Notification(
                                        "success",
                                        "PDF Solution alterado"
                                    );

                                    loading(idLoading);
                                }
                            );
                        } else {
                            setFile({
                                file,
                                preview: URL.createObjectURL(file),
                            });
                        }
                    }}
                    onDeleteFile={() => {
                        if (file.id) {
                            Notification("warning", "Não é possivel remover");
                        } else {
                            setFile({});
                        }
                    }}
                />
            </div>
            {!image.id && (
                <div>
                    <Button variant="success" onClick={handleSubmit}>
                        Enviar
                    </Button>
                </div>
            )}
        </Section>
    );
}

export default Index;
