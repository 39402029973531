import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Styled from "styled-components";

import api from "../../service/api";

import useLoading from "../../hooks/useLoading";

import { Error } from "../../modules/Notifications";

import Img from "../Img";

import { ppLogo, praticLogo } from "./files-name";

const LinesGrid = Styled(Row)`
    display: flex;
    justify-content: center;
    align-items: center;

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem!important;
    }
`;

const GridPratic = Styled(Row)`
    display: ${(props) => (props.show ? "flex" : "none")};

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem!important;
    }
`;

const GridPP = Styled(Row)`
    display: ${(props) => (props.show ? "flex" : "none")};

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem!important;
    }
`;

const CategoryCatalog = () => {
    const [isPraticOpened, setPraticToggle] = useState(false);

    const [isPPOpened, setPPToggle] = useState(false);

    const [ecobrite, setEcobrite] = useState({});

    const [solution, setSolution] = useState({});

    const [pratic, setPratic] = useState([
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
    ]);

    const [pp, setPP] = useState([
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
        { file: "", image: "" },
    ]);

    const { loading } = useLoading();

    const handleTogglePratic = () => {
        isPraticOpened ? setPraticToggle(false) : setPraticToggle(true);
        setPPToggle(false);
    };

    const handleTogglePP = () => {
        isPPOpened ? setPPToggle(false) : setPPToggle(true);
        setPraticToggle(false);
    };

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/configuracoes-produtos-mercados",
                    {
                        headers: {
                            Authorization: "",
                        },
                    }
                );

                response.data.forEach((item) => {
                    switch (item.type) {
                        case 0:
                            const ecobrite = {
                                image: `${api.defaults.baseURL}${item.object.image.url}`,
                                file: `${api.defaults.baseURL}${item.object.file.url}`,
                            };
                            setEcobrite(ecobrite);
                            break;

                        case 1:
                            const solution = {
                                image: `${api.defaults.baseURL}${item.object.image.url}`,
                                file: `${api.defaults.baseURL}${item.object.file.url}`,
                            };
                            setSolution(solution);
                            break;

                        case 2:
                            if (Array.isArray(item.object)) {
                                const pp = item.object.map((item) => {
                                    return {
                                        image: `${api.defaults.baseURL}${item.image.url}`,
                                        file: `${api.defaults.baseURL}${item.file.url}`,
                                    };
                                });

                                setPP(pp);
                            }
                            break;

                        case 3:
                            if (Array.isArray(item.object)) {
                                const pratic = item.object.map((item) => {
                                    return {
                                        image: `${api.defaults.baseURL}${item.image.url}`,
                                        file: `${api.defaults.baseURL}${item.file.url}`,
                                    };
                                });

                                setPratic(pratic);
                            }
                            break;

                        default:
                            break;
                    }
                });

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    return (
        <>
            <header>
                <h2>Catálogo Por Linha</h2>
            </header>
            <LinesGrid>
                <Col md="3" sm="12">
                    <a
                        href={ecobrite.file || ""}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={ecobrite.image || ""} alt="Linha Ecobrite" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={solution.file || ""}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={solution.image || ""}
                            alt="Linha Solution"
                            style={{ width: "160px" }}
                        />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <img
                        className="cursor-pointer"
                        src={ppLogo}
                        alt="Linha Produtos Profissionais"
                        onClick={handleTogglePP}
                    />
                </Col>
                <Col md="3" sm="12">
                    <img
                        className="cursor-pointer"
                        src={praticLogo}
                        alt="Linha Pratic"
                        onClick={handleTogglePratic}
                    />
                </Col>
            </LinesGrid>
            <GridPratic show={isPraticOpened}>
                <Col md="3" sm="12">
                    <a
                        href={pratic[0].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pratic[0].image}
                            alt="Pratic 1"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pratic[1].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pratic[1].image}
                            alt="Pratic 2"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pratic[2].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pratic[2].image}
                            alt="Pratic 3"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pratic[3].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img
                            src={pratic[3].image}
                            alt="Pratic 4"
                            max-width="200px"
                            className="border-0 shadow-none"
                        />
                    </a>
                </Col>
            </GridPratic>

            <GridPP show={isPPOpened}>
                <Col md="3" sm="12">
                    <a
                        href={pp[0].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[0].image} alt="PP 1" max-width="200px" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pp[1].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[1].image} alt="PP 2" max-width="200px" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pp[2].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[2].image} alt="PP 3" max-width="200px" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pp[3].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[3].image} alt="PP 4" max-width="200px" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pp[4].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[4].image} alt="PP 5" max-width="200px" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pp[5].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[5].image} alt="PP 6" max-width="200px" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pp[6].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[6].image} alt="PP 7" max-width="200px" />
                    </a>
                </Col>
                <Col md="3" sm="12">
                    <a
                        href={pp[7].file}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Img src={pp[7].image} alt="PP 8" max-width="200px" />
                    </a>
                </Col>
            </GridPP>
        </>
    );
};

export default CategoryCatalog;
