import React from "react";

import logo from "../../../assets/Images/logo-inputs.png";

import Img from "../../Img";

import { Container, Header } from "./styles";

function Index({ children, title, client }) {
    return (
        <Container>
            <Header>
                <Img
                    src={logo}
                    alt="Logo Inputs"
                    className="border-0 shadow-none"
                />

                <h2>{title}</h2>

                <span>
                    Bem-vindo, <strong>{client.Nome}</strong>
                </span>
            </Header>

            <main>{children}</main>
        </Container>
    );
}

export default Index;
