import React from "react";
import { useCallback, useEffect } from "react";
import api from "../../../service/api";
import { useHistory } from "react-router";
import ReactDOM from "react-dom";
import { FiFolder } from "react-icons/fi";

import DataTables from "../../../modules/DataTables";
import { Error as notificationError } from "../../../modules/Notifications";

import useLoading from "../../../hooks/useLoading";

function useAccessoriesAndEquipment() {
    const history = useHistory();

    const { loading } = useLoading();

    const setReactComponentinTable = useCallback(() => {
        const handleEdit = (id) => {
            history.push(`/ctop/acessorio-e-equipamento/${id}`);
        };

        const setReactComponentinTable = () => {
            const editarEls = document.querySelectorAll(".editar");

            for (const editarEl of editarEls) {
                ReactDOM.render(
                    <FiFolder
                        className="cursor-pointer"
                        onClick={() => {
                            handleEdit(editarEl.dataset.id);
                        }}
                    />,
                    document.getElementById(editarEl.id)
                );
            }
        };

        setReactComponentinTable();
    }, [history]);

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/acessorios-e-equipamentos?_limit=-1"
                );

                const serializedData = response.data.map((item) => {
                    const { id, nome } = item;

                    return {
                        nome,
                        Abrir: `<div class="editar" data-id="${id}" id="editar-${id}"></div>`,
                    };
                });

                DataTables(
                    "#accessories-and-equipment-table",
                    serializedData,
                    [
                        { title: "Nome", data: "nome" },
                        { title: "Abrir", data: "Abrir" },
                    ],
                    () => {
                        setReactComponentinTable();
                    }
                );

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        };

        show();
    }, [loading, setReactComponentinTable]);
}

export default useAccessoriesAndEquipment;
