import React, { useState, useEffect } from "react";
import { FaPaperPlane } from "react-icons/fa";

import Button from "../Button";
import ButtonsGrid from "../ButtonsGrid";
import Form from "../Form";
import Input, { TextArea } from "../Input";

import useLoading from "../../hooks/useLoading";

import api from "../../service/api";
import Mail from "../../service/mail";

import Notification, { Error } from "../../modules/Notifications";

import $ from "jquery";
import "jquery-mask-plugin";

const ContactForm = () => {
    const [data, setData] = useState({});

    const { loading } = useLoading();

    useEffect(() => {
        $("#telephone").mask("(00) 0000-0000");
        $("#cellphone").mask("(00) 0 0000-0000");
    }, []);

    const handleHtml = () => {
        return `
                <!DOCTYPE html>
                <html>
                <head>
                    <style>
                        .container {
                            position: relative;
                            width: 100%;
                        }

                        * {
                            margin: 0;
                            padding: 0;
                        }

                        h1 {
                            color: #38733e;
                            margin-bottom: 1rem;
                        }

                        .campo {
                            margin-bottom: 1rem;
                        }

                        .campo h2 {
                            color: #38733e;
                            margin-right: 0.1rem;
                            display: flex;
                        }

                        .campo span {
                            color: black;
                        }
                    </style>
                </head>
                <body>
                    <h1>Novo contato pelo site</h1>
                    <div class="container">
                        <div class="campo">
                            <h2>Nome</h2>
                            <span>${data.name}</span>
                        </div>
                        <div class="campo">
                            <h2>Empresa</h2>
                            <span>${data.company}</span>
                        </div>
                        <div class="campo">
                            <h2>Telefone Fixo</h2>
                            <span>${data.telephone}</span>
                        </div>
                        <div class="campo">
                            <h2>Telefone Celular</h2>
                            <span>${data.cellphone}</span>
                        </div>
                        <div class="campo">
                            <h2>Email</h2>
                            <span>${data.email}</span>
                        </div>
                        <div class="campo">
                            <h2>Mensagem</h2>
                            <span>${data.message}</span>
                        </div>
                    </div>
                </body>
            </html>`;
    };

    const handleValidade = () => {
        if (!data.name) {
            return { status: false, message: "Nome é obrigatório" };
        }
        if (!data.company) {
            return { status: false, message: "Empresa é obrigatório" };
        }
        if (!data.telephone) {
            return { status: false, message: "Telefone é obrigatório" };
        }
        if (!data.cellphone) {
            return { status: false, message: "Celular é obrigatório" };
        }
        if (!data.email) {
            return { status: false, message: "Email é obrigatório" };
        }
        if (!data.message) {
            return { status: false, message: "Mensagem é obrigatório" };
        }

        return { status: true };
    };

    const submit = (event) => {
        event.preventDefault();
        const send = async () => {
            const idLoading = loading();

            try {
                const validate = handleValidade();
                if (!validate.status) {
                    return Notification("warning", validate.message);
                }

                await api.post(
                    "/email",
                    {
                        to: Mail.contact,
                        subject: "Novo contato pelo site",
                        html: handleHtml(),
                    },
                    {
                        headers: {
                            Authorization: "",
                        },
                    }
                );

                setData({
                    name: "",
                    company: "",
                    telephone: "",
                    cellphone: "",
                    email: "",
                    message: "",
                });

                Notification("success", "Mensagem enviada");

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        send();
    };

    return (
        <Form className="contact-form" onSubmit={submit}>
            <p>Se preferir, envie-nos um e-mail:</p>
            <Input
                label="Nome*"
                type="text"
                value={data.name}
                onChange={(event) =>
                    setData({ ...data, name: event.target.value })
                }
            />
            <Input
                label="Empresa*"
                type="text"
                value={data.company}
                onChange={(event) =>
                    setData({ ...data, company: event.target.value })
                }
            />
            <Input
                label="Telefone*"
                type="text"
                value={data.telephone}
                id="telephone"
                onChange={(event) =>
                    setData({ ...data, telephone: event.target.value })
                }
            />
            <Input
                label="Celular*"
                type="text"
                value={data.cellphone}
                id="cellphone"
                onChange={(event) =>
                    setData({ ...data, cellphone: event.target.value })
                }
            />
            <Input
                label="Email*"
                type="text"
                value={data.email}
                onChange={(event) =>
                    setData({ ...data, email: event.target.value })
                }
            />

            <TextArea
                label="Mensagem*"
                value={data.message}
                onChange={(event) =>
                    setData({ ...data, message: event.target.value })
                }
            />
            <ButtonsGrid>
                <Button type="submit" variant="success">
                    <FaPaperPlane /> Enviar
                </Button>
            </ButtonsGrid>
        </Form>
    );
};

export default ContactForm;
