import React from "react";
import Styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import Container from "../../components/Container";
import News from "../../components/News";
import Spotlight from "../../components/Spotlight";
import Card from "../../components/Card";
import CookiesPopup from "../../components/CookiesPopup";

import Company from "../../assets/Images/company/empresa1.jpg";
import LogoInputs from "../../assets/Images/logo-inputs.jpg";
import LogoGS from "../../assets/Images/logo-gs.jpg";

const GridCards = Styled(Row)`
`;

const Home = () => {
    return (
        <>
            <CookiesPopup />
            <Container Spotlight={Spotlight} News={News}>
                <GridCards>
                    <Col md="4" sm="12" className="d-flex justify-center">
                        <Card
                            image={Company}
                            text="Há mais de vinte e cinco anos a Noordhen Brasil vem fornecendo a todo o mercado brasileiro soluções em produtos e sistemas de alta qualidade em todos os segmentos onde atua."
                            textButton="Saiba Mais"
                            url="/empresa"
                        />
                    </Col>
                    <Col md="4" sm="12" className="d-flex justify-center">
                        <Card
                            image={LogoInputs}
                            text="Apresentamos ao mercado nossa nova plataforma digital
                        INPUTS 360.
                        Nossa nova plataforma digital para atender as crescentes
                        demandas do mercado..."
                            textButton="Saiba Mais"
                            url="/about-inputs"
                        />
                    </Col>
                    <Col md="4" sm="12" className="d-flex justify-center">
                        <Card
                            image={LogoGS}
                            text="Mirando as oportunidades no exigente e crescente mercado
                        de produtos verdes e veganos, a Noordhen Brasil, sempre
                        atenta às necessidades do mercado.."
                            textButton="Saiba Mais"
                            url="/gs-solutions"
                        />
                    </Col>
                </GridCards>
            </Container>
            <script src="https://wbot.chat/index.js" token="4c10ae0fed469c8270ef7748632a07fe"></script>
        </>
    );
};

export default Home;
