import React from "react";
import Styled from "styled-components";
import { Col } from "react-bootstrap";
import { FaTag } from "react-icons/fa";

const Container = Styled(Col).attrs({
    className: "col-lg-3 col-md-6 col-12",
})`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.8rem !important;
`;

const Line = Styled.div`
    background-color: var(--white);
    
    width: 80%;
    padding: 0.5rem 0.8rem !important;
    
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50px;

    box-shadow: var(--shadow);

    cursor: pointer;

    color: ${(props) => (props.checked ? "var(--green-3)" : "var(--gray-2)")};

    transition: 150ms;

    & svg {
        margin-right: 0.5rem!important;
    }

    &:hover span, &:hover svg {
        opacity: 70%;
        color: var(--green-3);
    }

`;

const Index = ({ label, checked, onClick }) => {
    return (
        <Container>
            <Line checked={checked} onClick={onClick}>
                <FaTag />
                <span>{label}</span>
            </Line>
        </Container>
    );
};

export default Index;
