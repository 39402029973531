import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { FaPaperPlane, FaTrash } from "react-icons/fa";

import Container from "../../../components/Admin/Container";
import Input, { Switch } from "../../../components/Input";
import Button from "../../../components/Button";
import ButtonsGrid from "../../../components/ButtonsGrid";
import Form from "../../../components/Form";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";

import Notification from "../../../modules/Notifications";
import Confirm from "../../../modules/AlertConfirm";

const NewClient = (props) => {
    const { id } = props.match.params;

    const history = useHistory();

    const [data, setData] = useState({});

    const { loading } = useLoading();

    useEffect(() => {
        const { id } = props.match.params;

        async function show() {
            const idLoading = loading();

            const response = await api.get(`/clientes/${id}`);

            setData(response.data);

            loading(idLoading);
        }

        if (id) show();
    }, [loading, props.match.params]);

    const handleValidate = () => {
        if (!data.Nome) {
            return { status: false, message: "Nome é obrigatório" };
        }

        if (!data.Senha && !props.match.params.id) {
            return { status: false, message: "Senha é obrigatório" };
        }

        return { status: true };
    };

    const destroy = async (event) => {
        event.preventDefault();

        Confirm(
            "Remover Cliente",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/clientes/${id}`);

                    Notification("success", "Cliente removido");

                    loading(idLoading);

                    history.push("/ctop/clientes");
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    const create = async () => {
        const idLoading = loading();
        try {
            await api.post("/clientes", data);

            Notification("success", "Cliente cadastrado");

            history.push("/ctop/clientes");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const update = async () => {
        const idLoading = loading();
        try {
            await api.put(`/clientes/${id}`, data);

            Notification("success", "Cliente atualizado");

            loading(idLoading);

            history.push("/ctop/clientes");
        } catch (error) {
            Error(error);
        }
    };

    const submit = (event) => {
        event.preventDefault();

        const validate = handleValidate();
        if (!validate.status) {
            return Notification("warning", validate.message);
        }

        if (!id) {
            return create();
        }

        return update();
    };

    return (
        <Container
            pageTitle={`${
                !props.match.params.id ? "Cadastrar" : "Editar"
            } Cliente`}
        >
            <Form className="col-md-4 col-sm-12 m-auto" onSubmit={submit}>
                <Input
                    id="username"
                    type="text"
                    label="Nome da Empresa*"
                    value={data.Nome}
                    onChange={(event) => {
                        setData({ ...data, Nome: event.target.value });
                    }}
                />
                <Input
                    type="text"
                    label="Login*"
                    value={data.login}
                    onChange={(event) => {
                        setData({ ...data, login: event.target.value });
                    }}
                    maxLength={14}
                />
                <Input
                    id="password"
                    type="password"
                    label="Senha*"
                    value={data.Senha}
                    onChange={(event) => {
                        setData({ ...data, Senha: event.target.value });
                    }}
                />
                <Row>
                    <Col style={{ margin: "0 auto !important" }}>
                        <Switch
                            id="cdoc"
                            label="CDOC"
                            checked={data.CDOC}
                            onChange={() => {
                                setData({ ...data, CDOC: !data.CDOC });
                            }}
                        />
                    </Col>
                    <Col>
                        <Switch
                            id="requests"
                            label="Pedidos"
                            checked={data.Pedidos}
                            onChange={() => {
                                setData({ ...data, Pedidos: !data.Pedidos });
                            }}
                        />
                    </Col>
                </Row>
                <ButtonsGrid>
                    <Button type="submit" variant="success">
                        <FaPaperPlane />
                        Enviar
                    </Button>
                    {id && (
                        <Button variant="outline-danger" onClick={destroy}>
                            <FaTrash />
                            Remover
                        </Button>
                    )}
                </ButtonsGrid>
            </Form>
        </Container>
    );
};

export default NewClient;
