import styled from "styled-components";

const Index = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 2rem 0 !important;

    button {
        margin-right: 1rem !important;
    }
`;

export default Index;
