import React from "react";
import PageDefaultPublicArea from "../../components/Container";

const PageNotFound404 = () => {
    return (
        <PageDefaultPublicArea>
            <h1>Página Não Encontrada</h1>
        </PageDefaultPublicArea>
    );
};

export default PageNotFound404;
