import React from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";

import CDOCContainer from "../Container";
import Img from "../../Img";
import Label from "../../Label";
import Product from "../Product";

import useProducts from "./useProducts";

import { Container, Figure } from "./styles";

function Products({ client }) {
    const {
        aplications,
        selectedAplication,
        applicationProducts,
        product,
        products,
        handleSelectAplication,
        handleSelectProduct,
    } = useProducts({ client });

    return (
        <CDOCContainer {...{ client, title: "Produtos" }}>
            <Container>
                <Row>
                    <Col sm="12">
                        <h3>Catálogo por Aplicação</h3>
                        <Label>
                            <span>
                                Escolha abaixo ou digite a palavra chave
                            </span>
                            <Select
                                options={aplications}
                                value={selectedAplication}
                                onChange={handleSelectAplication}
                            />
                        </Label>
                        {applicationProducts.length !== 0 && (
                            <Row>
                                {applicationProducts.map((product) => (
                                    <Figure
                                        key={product.ID}
                                        onClick={() => {
                                            handleSelectProduct(product.ID);
                                        }}
                                        className="cursor-pointer"
                                    >
                                        <Img
                                            src={product.image}
                                            alt="Produto noordhen"
                                            max-height="110px"
                                            max-width="200px"
                                            className="border-0 shadow-none"
                                        />
                                    </Figure>
                                ))}
                            </Row>
                        )}
                    </Col>
                    <Col sm="12">
                        <h3>Busca por nome</h3>
                        <Label className="col">
                            <span>
                                Escolha abaixo ou digite a palavra chave
                            </span>
                            <Select
                                id="search-product"
                                label="Nome do Produto"
                                type="text"
                                options={products}
                                onChange={(event) => {
                                    handleSelectProduct(event.value);
                                }}
                            />
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col id="product">
                        <h2>Produto</h2>
                        {product > 0 && (
                            <Product {...{ client, id: product }} />
                        )}
                    </Col>
                </Row>
            </Container>
        </CDOCContainer>
    );
}

export default Products;
