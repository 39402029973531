import { useEffect, useState } from "react";

import api, { publicRequest } from "../../../service/api";

import { Error as notificationError } from "../../../modules/Notifications";

import useLoading from "../../../hooks/useLoading";

function useGraphicMaterial(client) {
    const [graphicMaterials, setGraphicMaterials] = useState([]);

    const { loading } = useLoading();

    useEffect(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await api.get(
                    "/materiais-graficos?_limit=-1",
                    publicRequest
                );

                const serializedData = response.data.map((item) => {
                    let arquivo = [];
                    if (Array.isArray(item.arquivo)) {
                        arquivo = item.arquivo.map((arq) => {
                            return `${api.defaults.baseURL}${arq.url}`;
                        });
                    }

                    return {
                        id: item.id,
                        nome: item.nome,
                        arquivo,
                        imagem: `${api.defaults.baseURL}${item.imagem.url}`,
                    };
                });

                setGraphicMaterials(serializedData);

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    async function createLogCDOC(cliente, titulo) {
        const idLoading = loading();

        try {
            await api.post(
                "/log-cdocs",
                {
                    cliente,
                    cdoc: 6,
                    titulo,
                },
                publicRequest
            );

            loading(idLoading);
        } catch (error) {
            console.error(error);

            loading(idLoading);
        }
    }

    function handleSelectDocument(documents, title) {
        documents.forEach((doc) => {
            let element = document.createElement("a");
            element.setAttribute("href", doc);
            element.setAttribute("download", true);
            element.setAttribute("target", "_blank");

            element.click();
        });

        createLogCDOC(client, title);
    }

    return { graphicMaterials, handleSelectDocument };
}

export default useGraphicMaterial;
