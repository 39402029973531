import { useCallback, useEffect, useState } from "react";

import api, { publicRequest } from "../../../service/api";

import { Error as notificationError } from "../../../modules/Notifications";

import useLoading from "../../../hooks/useLoading";

function useHomeCarousel() {
    const [carousel, setCarousel] = useState([]);

    const [ativo, setAtivo] = useState(false);

    const { loading } = useLoading();

    const index = useCallback(() => {
        async function index() {
            const idLoading = loading();

            try {
                const response = await api.get("/configuracoes", publicRequest);

                const settings = response.data[0];

                let carousel = settings.CDOCCarousel.map((file) => {
                    return {
                        ...file,
                        preview: `${api.defaults.baseURL}${file.url}`,
                    };
                });

                setCarousel(carousel);

                const popup = settings.CDOCPopup;
                if (popup.ativo) {
                    setAtivo(true);

                    document.querySelector("#popup-container").innerHTML =
                        popup.html;
                }

                loading(idLoading);
            } catch (error) {
                notificationError(error);

                loading(idLoading);
            }
        }

        index();
    }, [loading]);

    useEffect(() => {
        index();
    }, [index]);

    return { ativo, carousel };
}

export default useHomeCarousel;
