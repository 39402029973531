import React from "react";
import { Row, Col } from "react-bootstrap";

import Input from "../../Input";
import Img from "../../Img";
import Fieldset from "../../Fieldset";

import { Container, GridCDOC, CDOC, Videos } from "./styles";

import useProduct from "./useProduct";

const Product = ({ id, client }) => {
    const {
        data,
        productInfos,
        videos,
        handleSelectDocument,
        createLogCDOC,
    } = useProduct({
        id,
        client,
    });

    return (
        <Container>
            <Row>
                <Col className="d-flex justify-center">
                    <Img
                        src={data.image}
                        alt="CDOCs Produto Noorhen"
                        max-width="200px"
                        max-height="200px"
                        min-width="200px"
                    />
                </Col>
                <Col>
                    <Input
                        type="text"
                        id="Titulo"
                        label="Título:"
                        value={data.Titulo}
                        readOnly
                    />
                </Col>
            </Row>
            <Fieldset className="form__checkbox-products--border">
                <h3>CDOCS</h3>
                <Row className="justify-center">
                    <GridCDOC>
                        <CDOC
                            onClick={(e) => {
                                e.preventDefault();

                                handleSelectDocument("CDOC1");
                            }}
                        >
                            <span>Literatura</span>
                        </CDOC>
                        {!data.CDOC1 ? (
                            <p>
                                Não disponível.
                                <br /> Solicitar a Empresa
                            </p>
                        ) : (
                            <p>Clique para baixar</p>
                        )}
                    </GridCDOC>
                    <GridCDOC>
                        <CDOC
                            onClick={(e) => {
                                e.preventDefault();

                                handleSelectDocument("CDOC2");
                            }}
                        >
                            <span>FISPQ</span>
                        </CDOC>
                        {!data.CDOC2 ? (
                            <p>
                                Não disponível.
                                <br /> Solicitar a Empresa
                            </p>
                        ) : (
                            <p>Clique para baixar</p>
                        )}
                    </GridCDOC>
                    <GridCDOC>
                        <CDOC
                            onClick={(e) => {
                                e.preventDefault();

                                handleSelectDocument("CDOC3");
                            }}
                        >
                            <span>LAUDOS</span>
                        </CDOC>
                        {!data.CDOC3 ? (
                            <p>
                                Esse produto não pussui laudos obrigatórios de
                                acordo com a legislação vigente
                            </p>
                        ) : (
                            <p>Clique para baixar</p>
                        )}
                    </GridCDOC>
                    <GridCDOC>
                        <CDOC
                            onClick={(e) => {
                                e.preventDefault();

                                handleSelectDocument("CDOC4");
                            }}
                        >
                            <span>Conheça melhor o produto para vender</span>
                        </CDOC>
                        {!data.CDOC4 ? (
                            <p>Não disponível</p>
                        ) : (
                            <p>Clique para baixar</p>
                        )}
                    </GridCDOC>
                    <GridCDOC>
                        <CDOC
                            onClick={(e) => {
                                e.preventDefault();

                                productInfos.forEach((item) => {
                                    window.open(item, "_blank");
                                });
                                createLogCDOC(client, 5);
                            }}
                        >
                            <span>Material publicitário de apoio</span>
                        </CDOC>
                        {productInfos.length === 0 ? (
                            <p>Não disponível</p>
                        ) : (
                            <p>Clique para baixar</p>
                        )}
                    </GridCDOC>
                </Row>
            </Fieldset>
            <Videos>
                <h3>Vídeos</h3>
                {videos.map((video) => (
                    <li key={Math.random()} className="video-item">
                        <a
                            href={video.link}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {video.link}
                        </a>
                    </li>
                ))}
            </Videos>
        </Container>
    );
};

export default Product;
