import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import useLoading from "../../../hooks/useLoading";

import api from "../../../service/api";

import Notification, { Error } from "../../../modules/Notifications";
import Confirm from "../../../modules/AlertConfirm";

const useLines = (props) => {
    const { id } = props.match.params;

    const history = useHistory();

    const [lines, setLines] = useState([]);

    const [data, setData] = useState({
        Linha: "",
        ordem: undefined,
    });

    const [title, setTitle] = useState("");

    const { loading } = useLoading();

    useEffect(() => {
        const show = async () => {
            const idLoading = loading();
            try {
                const response = await api.get(`/linhas/${id}`);

                console.log(response.data);

                setData(response.data);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        if (id) {
            setTitle("Editar Linha");
            show();
        } else {
            setData("");
            setTitle("Cadastrar Linha");
        }
    }, [id, loading]);

    const index = useCallback(() => {
        const index = async () => {
            const idLoading = loading();
            try {
                const response = await api.get("/linhas?_sort=ordem:ASC");

                setLines(response.data);

                loading(idLoading);
            } catch (error) {
                Error(error);

                loading(idLoading);
            }
        };

        index();
    }, [loading]);

    useEffect(() => {
        index();
    }, [index]);

    const handleValidate = () => {
        return lines.find((line) => line.Linha === data)
            ? { status: false, message: "Linha já cadastrada" }
            : { status: true };
    };

    const create = async () => {
        const idLoading = loading();
        try {
            await api.post("/linhas", data);

            setData("");

            Notification("success", "Linha cadastrada");

            index();

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const update = async () => {
        const idLoading = loading();

        try {
            await api.put(`/linhas/${id}`, data);

            setData("");
            Notification("success", "Linha atualizada");

            index();

            history.push("/ctop/produtos/linhas");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    const destroy = (id) => {
        Confirm(
            "Remover Linha",
            "Tem certeza que deseja remover ?",
            async () => {
                const idLoading = loading();

                try {
                    await api.delete(`/linhas/${id}`);

                    Notification("success", "Linha removida");

                    loading(idLoading);

                    index();
                } catch (error) {
                    Error(error);

                    loading(idLoading);
                }
            }
        );
    };

    const submit = (event) => {
        event.preventDefault();

        const validate = handleValidate();
        if (!validate.status) {
            return Notification("error", validate.message);
        }

        if (!id) return create();

        return update();
    };

    const handlerOnChange = (event) => {
        const { name, value } = event.target;

        setData({ ...data, [name]: value });
    };

    const handlerOnClear = () => {
        setData({ Linha: "", ordem: "" });

        history.push("/ctop/produtos/linhas");
    };

    const handlerShowLine = (id) => {
        history.push(`/ctop/produtos/linhas/${id}`);
    };

    return {
        title,
        data,
        id,
        lines,
        submit,
        destroy,
        handlerOnChange,
        handlerOnClear,
        handlerShowLine,
    };
};

export default useLines;
