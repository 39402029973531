import styled from "styled-components";
import { Col } from "react-bootstrap";

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    & img {
        margin: 0 1rem 1rem 0 !important;
    }
`;

export const FormGrid = styled(Col)`
    margin: 0 auto !important;
`;
