import React, { useState, useEffect, useCallback } from "react";
import Styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import api from "../../service/api";

import useLoading from "../../hooks/useLoading";

import Img from "../Img";

const Container = Styled.div`
    width: 100%;

    background-color: var(--gray-4);

    & h1 {
        margin-left: 2rem!important;
    }
`;

const GridNews = Styled(Row)`
    margin: 2rem!important;
`;

const New = Styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & a {
        background-color: var(--white);
        margin: 1rem!important;
        color: var(--black);
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const News = () => {
    const [journal, setJournal] = useState([]);

    const { loading } = useLoading();

    const getttingNews = useCallback(() => {
        const getttingNews = async () => {
            const idLoading = loading();

            const serverAnswer = await api.get(
                "/noticias?_sort=data:DESC&ativo=true&_limit=-1",
                { headers: { Authorization: "" } }
            );

            loading(idLoading);

            return setJournal(serverAnswer.data);
        };

        getttingNews();
    }, [loading]);

    useEffect(() => {
        getttingNews();
    }, [getttingNews]);

    return (
        <>
            {journal.length > 0 && (
                <Container>
                    <GridNews>
                        {journal.map((j) => (
                            <New key={j.id} md="4" sm="12">
                                <a
                                    href={j.noticia}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Img
                                        src={j.imagem}
                                        alt={j.titulo}
                                        max-width="200px"
                                        max-height="200px"
                                    />
                                </a>
                            </New>
                        ))}
                    </GridNews>
                </Container>
            )}
        </>
    );
};

export default News;
