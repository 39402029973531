import React, { useState } from "react";

import Container from "../../../components/Admin/Container";
import Button from "../../../components/Button";
import SettingsSpotlight from "../../../components/SettingsSpotlight";
import SettingsProductsAndMarkets from "../../../components/SettingsProductsAndMarkets";
import SettingsTermsOfUseCDOC from "../../../components/SettingsTermsOfUseCDOC";
import SettingsPopupCookies from "../../../components/SettingsPopupCookies";
import SettingsCDOC from "../../../components/SettingsCDOC";

import { Main, SectionNav, NavItem } from "./styles";

function Index() {
    const [navState, setNavState] = useState(1);

    function handleNav({ target }) {
        setNavState(parseInt(target.dataset.value));
    }

    return (
        <Container pageTitle="Configurações">
            <Main>
                <nav className="row">
                    <NavItem>
                        <Button
                            variant="success"
                            disabled={navState === 1}
                            data-value="1"
                            onClick={handleNav}
                        >
                            Destaques
                        </Button>
                    </NavItem>
                    <NavItem>
                        <Button
                            variant="success"
                            data-value="2"
                            disabled={navState === 2}
                            onClick={handleNav}
                        >
                            Produtos e Mercados
                        </Button>
                    </NavItem>
                    <NavItem>
                        <Button
                            variant="success"
                            data-value="3"
                            disabled={navState === 3}
                            onClick={handleNav}
                        >
                            Termos e condições de uso
                        </Button>
                    </NavItem>
                    <NavItem>
                        <Button
                            variant="success"
                            data-value="4"
                            disabled={navState === 4}
                            onClick={handleNav}
                        >
                            popup de cookies
                        </Button>
                    </NavItem>
                    <NavItem>
                        <Button
                            variant="success"
                            data-value="5"
                            disabled={navState === 5}
                            onClick={handleNav}
                        >
                            Inputs 360°
                        </Button>
                    </NavItem>
                </nav>
                <hr />
                <SectionNav show={navState === 1}>
                    <SettingsSpotlight />
                </SectionNav>
                <SectionNav show={navState === 2}>
                    <SettingsProductsAndMarkets />
                </SectionNav>
                <SectionNav show={navState === 3}>
                    <SettingsTermsOfUseCDOC />
                </SectionNav>
                <SectionNav show={navState === 4}>
                    <SettingsPopupCookies />
                </SectionNav>
                <SectionNav show={navState === 5}>
                    <SettingsCDOC />
                </SectionNav>
            </Main>
        </Container>
    );
}

export default Index;
