import React from "react";
import Container from "../../../components/Admin/Container";
import Img from "../../../components/Img";
import Destaque from "../../../assets/Images/destaque-admin.png";

const HomePageAdmin = () => {
    return (
        <Container pageTitle="Página Inicial">
            <Img src={Destaque} width="1200px" height="600px" />
        </Container>
    );
};

export default HomePageAdmin;
