import React, { useState, useEffect } from "react";
import { FaSignInAlt } from "react-icons/fa";

import { Col } from "react-bootstrap";

import { useHistory } from "react-router-dom";
import Notification, { Error } from "../../../modules/Notifications";

import Input from "../../../components/Input";
import Form from "../../../components/Form";
import Button from "../../../components/Button";
import Img from "../../../components/Img";
import ButtonsGrid from "../../../components/ButtonsGrid";

import api from "../../../service/api";

import useLoading from "../../../hooks/useLoading";

import Logo from "../../../assets/Images/logo-noordhen.svg";

import "./styles.css";

const LoginArea = () => {
    const history = useHistory();
    const [identifier, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { loading } = useLoading();

    useEffect(() => {
        localStorage.clear();
    }, []);

    const handleLogin = async (event) => {
        const idLoading = loading();
        try {
            event.preventDefault();

            if (!identifier || !password) {
                return Notification(
                    "warning",
                    "Nome de usuário e senha são obrigatórios"
                );
            }

            const response = await api.post(
                "/auth/local",
                { identifier, password },
                { headers: { Authorization: "" } }
            );

            localStorage.setItem("token", `Bearer ${response.data.jwt}`);
            api.defaults.headers.Authorization = `Bearer ${response.data.jwt}`;

            history.push("/ctop/home");

            loading(idLoading);
        } catch (error) {
            Error(error);

            loading(idLoading);
        }
    };

    return (
        <div className="main-login row">
            <div className="logo-login col">
                <Img
                    src={Logo}
                    max-height="250px"
                    className="border-0 shadow-none"
                />
            </div>
            <div className="col">
                <div className="container-login">
                    <Col>
                        <h2>Área Admin</h2>
                    </Col>
                    <Form onSubmit={handleLogin}>
                        <Input
                            type="text"
                            label="Entrada"
                            placeholder="E-mail ou nome de usuário"
                            onChange={(event) =>
                                setUsername(event.target.value)
                            }
                        />
                        <Input
                            type="password"
                            label="Senha"
                            placeholder="Entre com a senha"
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                        />
                        <ButtonsGrid>
                            <Button variant="success" type="submit">
                                <FaSignInAlt />
                                Entrar
                            </Button>
                        </ButtonsGrid>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default LoginArea;
