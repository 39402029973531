import React from "react";
import { Link } from "react-router-dom";
import { FiPlusCircle } from "react-icons/fi";

import Container from "../../../components/Admin/Container";
import Table, { ResponsiveTable } from "../../../components/Table";

import useEvents from "./useEvents";

function Events() {
    useEvents();

    return (
        <Container pageTitle="Eventos">
            <div className="grid-add">
                <Link to="/ctop/evento" className="add">
                    <FiPlusCircle />
                </Link>
            </div>
            <ResponsiveTable>
                <Table id="events-table"></Table>
            </ResponsiveTable>
        </Container>
    );
}

export default Events;
