import React from "react";
import Styled from "styled-components";

import { Col } from "react-bootstrap";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

import Container from "../../components/Container";
import ContactForm from "../../components/ContactForm";

const ContactWays = Styled.div`
    & p {
        display: flex;
        align-items: center;
        margin-top: 0.5rem!important;
    }

    & p div {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--green);
        color: var(--white);
        padding: 0.7rem !important;
        border-radius: 120px;
        margin-right: 0.5rem!important;
    }
`;

const Contact = () => {
    const whatsAppNumber = "+55 (12) 9 8220.5641";
    const phoneNumber = "+55 (12) 3672.3866";
    return (
        <Container className="row">
            <Col md="6" sm="12">
                <header>
                    <h1>Canais de Contato</h1>
                </header>
                <p>
                    A Noordhen Brasil atende de Segunda à Sexta-feira, das 8h as
                    17h, exceto feriados.
                </p>
                <ContactWays>
                    <p>
                        <div>
                            <FaPhoneAlt />
                        </div>
                        <span>{phoneNumber}</span>
                    </p>
                    <p>
                        <div>
                            <FaWhatsapp />
                        </div>
                        <span>{whatsAppNumber}</span>
                    </p>
                </ContactWays>
            </Col>
            <Col md="6" sm="12">
                <ContactForm />
            </Col>
        </Container>
    );
};

export default Contact;
