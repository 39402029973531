import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./styles.css";

import Logo from "../../assets/Images/logo-noordhen.svg";
import LogoInputs from "../../assets/Images/logo-inputs.png";

import Img from "../../components/Img";

const Index = () => {
    return (
        <Navbar collapseOnSelect expand="lg" className="navbar-public">
            <Navbar.Brand className="navbar-public-logo">
                <Link to="/">
                    <Img
                        className="border-0 shadow-none"
                        src={Logo}
                        max-height="88px"
                    />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Link to="/empresa">Empresa</Link>
                    <Link to="/produtos-e-mercado">Produtos e Mercados</Link>
                    <Link to="/central-de-documentos">
                        <Img
                            src={LogoInputs}
                            className="border-0 shadow-none"
                            max-width="120px"
                        />
                    </Link>
                    <Link to="/contato">Contato</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Index;
