import React from "react";
import { Row, Col } from "react-bootstrap";

import useAccessoriesAndEquipments from "./useAccessoriesAndEquipments";

import Img from "../../Img";
import CDOCContainer from "../Container";

import { Container } from "./styles";

function HomologatedBrands({ client }) {
    const { data, handleSelectDocument } = useAccessoriesAndEquipments(
        client.id
    );

    return (
        <CDOCContainer {...{ client, title: "Acessórios e Equipamentos" }}>
            <Container>
                <Row>
                    {data.map((item) => (
                        <Col
                            md="4"
                            sm="12"
                            key={item.id}
                            className="d-flex justify-content-center"
                        >
                            <div
                                className="document"
                                onClick={() => {
                                    handleSelectDocument(
                                        item.arquivo,
                                        item.nome
                                    );
                                }}
                            >
                                <Img
                                    src={item.imagem}
                                    className="border-0 shadow-none"
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </CDOCContainer>
    );
}

export default HomologatedBrands;
