import Styled from "styled-components";

const Index = Styled.fieldset`
    border: 1px solid var(--green);
    margin: 2rem!important;
    padding: 1.5rem!important;

    & h3 {
        margin-bottom: 1rem!important;
    }
`;

export default Index;
