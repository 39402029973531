import React from "react";
import { FaPaperPlane } from "react-icons/fa";

import { Select as InputSelect, TextArea } from "../../Input";
import ButtonsGrid from "../../ButtonsGrid";
import Form from "../../Form";
import Button from "../../Button";
import CDOCContainer from "../Container";

import { Container } from "./styles";

import useContact from "./useContact";

function Contact({ client }) {
    const { data, handleSubmit, handleOnChange } = useContact(client);

    return (
        <CDOCContainer {...{ client, title: "Contato" }}>
            <Container>
                <Form className="col-md-7 col-sm-6" onSubmit={handleSubmit}>
                    <h3>Envie uma mensagem para Inputs 360°</h3>
                    <InputSelect
                        label="Assunto"
                        value={data.subject}
                        name="subject"
                        onChange={handleOnChange}
                    >
                        <option value="">Selecione...</option>
                        <option value="Solicitação de mix e aplicação">
                            Solicitação de mix e aplicação
                        </option>
                        <option value="Críticas e Sugestões">
                            Críticas e Sugestões
                        </option>
                        <option value="Outros">Outros</option>
                    </InputSelect>
                    <TextArea
                        label="Mensagem*"
                        name="message"
                        value={data.message}
                        onChange={handleOnChange}
                    />
                    <ButtonsGrid>
                        <Button variant="success" type="submit">
                            <FaPaperPlane />
                            Enviar
                        </Button>
                    </ButtonsGrid>
                </Form>
            </Container>
        </CDOCContainer>
    );
}

export default Contact;
